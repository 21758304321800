import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#262D3C",
        secondary: "#60A6A4",
        accent: "#AB47BC",
        success: "#008000",
        error: "#EA5A56"
      }
    }
  }
});
