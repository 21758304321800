<template>
  <v-card>
    <v-card-title>
      <UserFormModal :defaultInmo="inmo._id" type="create" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by Nombre, Telefono y Role."
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <TableUsers :users="users" :search="search" :loading="loading" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserFormModal from "./forms/ModalUserV2.vue";
import TableUsers from "./TableUsers.vue";
export default {
  name: "UsersByInmo",
  components: {
    TableUsers,
    UserFormModal
  },
  async created() {
    this.loading = true;
    await this.fetchUsersByInmo(this.inmo._id);
    this.loading = false;
  },
  data() {
    return {
      search: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters("root", {
      users: "getUsersByInmoInspect",
      inmo: "getInmoInspect"
    })
  },
  methods: {
    ...mapActions("root", {
      fetchUsersByInmo: "fetchUsersByInmo"
    })
  }
};
</script>
