import CoordinatorMiddleware from "../middlewares/coordinator-middleware";
const slug = "/coordinator";
const role = "Coordinator";

const routes = [
  /* Acceso solo a las propiedades */
  {
    path: slug,
    redirect: `${slug}/properties`,
    name: role
  },
  /* Propiedades */
  {
    path: `${slug}/properties`,
    name: `${role}PropertyView`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [CoordinatorMiddleware],
      title: "Propiedades"
    }
  },
  {
    path: `${slug}/:realEstateCode/property/list`,
    name: `${role}PropertyViewCode`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [CoordinatorMiddleware],
      title: "Propiedades"
    }
  },
  /* Desarrollos */
  {
    path: `${slug}/real-estate-development/list`,
    name: `${role}RealEstateDevelopmentView`,
    component: () =>
      import("../views/real-estate-development/AdminRealEstateDevelopmentView"),
    meta: {
      middleware: [CoordinatorMiddleware],
      title: "Desarrollos Inmobiliarios"
    }
  },
  {
    path: `${slug}/real-estate-development/create`,
    name: `${role}RealEstateDevelopmentCreateView`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentCreateView"
      ),
    meta: {
      middleware: [CoordinatorMiddleware],
      title: "Nuevo Desarrollo Inmobiliario"
    }
  },
  {
    path: `${slug}/real-estate-development/edit/:code`,
    name: `${role}RealEstateDevelopmentEditView`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentEditView"
      ),
    meta: {
      middleware: [CoordinatorMiddleware],
      title: "Editar Desarrollo Inmobiliario"
    }
  }
];

export default routes;
