import request from "../helpers/request";

// obtiene la lista de leads
export function AddLeadComment(leadId, commentText, userRole, contactId) {
  try {
    let data = {
      lead_id: leadId,
      comment: commentText,
      role: userRole,
      contact_id: contactId
    };

    return new Promise((resolve, reject) => {
      request("/lead/addComment", data)
        .then(response => {
          let lead = response.data.Lead;
          resolve({
            Lead: lead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
