<template>
  <div>
    <!-- show all results in developments -->
    Resultados: {{ `#${developments.length}` }}, selected:
    {{ `#${developmentsSelected.length}` }}
    <v-autocomplete
      v-model="developmentsSelected"
      :items="developments"
      label="Asignar Desarrollos"
      :loading="loading"
      :disabled="isDisabled"
      multiple
      chips
      :clearable="!isReadOnly"
      :readonly="isReadOnly"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "DevelopmentsSelectable",
  props: {
    value: {
      type: Array,
      required: true
    },
    real_estate_group_id: {
      type: String,
      required: false
    },
    role: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    developments: [],
    loading: false,
    developmentsSelected: []
  }),
  async created() {
    if (this.role === "developer") {
      this.fetchInmosByDeveloper();
    }
    if (this.real_estate_group_id && this.role !== "developer") {
      this.fetchDevelopments(this.real_estate_group_id);
    }
    this.developmentsSelected = this.value;
  },
  computed: {
    ...mapGetters("root", {
      getDevelopmentsByInmo: "getDevelopmentsByInmo",
      getDevelopmentsByInmoInspect: "getDevelopmentsByInmoInspect"
    }),
    ...mapState("root", {
      developmentsList: state => state.developmentsList
    }),
    isDisabled() {
      if (["marketing", "marketingv2"].includes(this.role)) return true;

      return this.loading || !this.developments.length;
    }
  },
  watch: {
    async real_estate_group_id(newVal) {
      if (newVal && this.role !== "developer") {
        this.fetchDevelopments(newVal);
      }
    },
    async role(newVal) {
      if (newVal === "developer") {
        this.fetchInmosByDeveloper();
        return;
      }
      if (this.real_estate_group_id) {
        this.fetchDevelopments(this.real_estate_group_id);
      }
    },
    value(newVal) {
      this.developmentsSelected = newVal;
    },
    developmentsSelected(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    ...mapActions("root", {
      fetchDevelopmentsList: "fetchDevelopmentsList",
      fetchDevelopmentsByInmo: "fetchDevelopmentsByInmo"
    }),
    async fetchDevelopments(real_estate_group_id) {
      this.loading = true;

      // Primero buscamos en el store los desarrollos de la inmobiliaria

      this.developments = this.getDevelopmentsByInmo(real_estate_group_id).map(
        development => {
          return {
            text: development.name,
            value: development._id
          };
        }
      );
      if (this.developmentsList.length) {
        this.loading = false;
        return;
      }
      // en caso de que no hay los buscamos en la api
      await this.fetchDevelopmentsByInmo(real_estate_group_id);
      this.developments = this.getDevelopmentsByInmoInspect.map(development => {
        return {
          text: development.name,
          value: development._id
        };
      });
      this.loading = false;
      return;
    },
    async fetchInmosByDeveloper() {
      this.loading = true;
      if (!this.developmentsList.length) {
        await this.fetchDevelopmentsList();
      }
      this.developments = this.developmentsList.map(inmo => {
        return {
          text: inmo.name,
          value: inmo._id
        };
      });
      this.loading = false;

      return;
    }
  }
};
</script>

<style></style>
