const ROLES = [
  {
    text: "Administrador",
    value: "admin"
  },
  {
    text: "Broker",
    value: "broker"
  },
  {
    text: "Marketing",
    value: "marketing"
  },
  {
    text: "Coordinador",
    value: "coordinator"
  },
  {
    text: "Desarrollador",
    value: "developer"
  },
  {
    text: "Marketing 2",
    value: "marketingv2"
  },
  {
    text: "Root",
    value: "root"
  }
];
/**
 * Get roles
 * @param {string[]} ommit - Roles to ommit
 */
export const getRoles = ommits => {
  return ROLES.filter(role => !ommits.includes(role.value));
};
