import { GetContactBrokerList } from "@/api/contact-broker-api.js";
import { SendNotification } from "@/api/notification-api.js";

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    selectContactBrokers: state => state.items
  },
  mutations: {
    setBrokers(state, brokers) {
      // actualiza la información de los brokers en Vuex
      state.items = brokers;
    }
  },
  actions: {
    fetchContactBrokers({ commit }) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          GetContactBrokerList()
            .then(res => {
              commit("setBrokers", res.ContactBroker);
              resolve();
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // prepara la consulta para notificar al broker que le fue asignado un lead
    notifyBrokerLeadAssigned(state, notifyData) {
      try {
        let message = notifyData.message;
        if (!message) message = "Se te ha asignado un nuevo lead";
        let data = {
          message: message,
          contact_id: notifyData.contactBrokerId,
          lead_id: notifyData.leadId,
          notificationType: "lead-assigned",
          role: "broker"
        };
        return new Promise(resolve => {
          // consultar API C28
          SendNotification(data)
            .then(res => {
              if (res.Notifiers) resolve();
              resolve();
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject;
      }
    }
  }
};
