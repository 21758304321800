<template>
  <v-data-table
    :headers="headers"
    :items="users"
    :search="search"
    class="elevation-1"
    :loading="loading"
    loading-text="Cargando usuarios..."
  >
    <template v-slot:item.name="{ item }">
      <v-list-item>
        <v-list-item-avatar>
          <Customdefaultimage
            :src="getImg(item)"
            default="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item.role="{ item }">
      <ChangeRoleUser :role="item.role" :userId="item._id" />
    </template>
    <template v-slot:item.contactData.realEstateName="{ item }">
      {{ getInmo(item) }}
    </template>
    <template v-slot:item.contactData.active="{ item }">
      <v-chip :color="getStatus(item).color" dark>
        {{ getStatus(item).text ?? "Undefined" }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <UserFormModal :currentUser="item" type="view" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Customdefaultimage from "./common/CustomDefaultImage.vue";
import UserFormModal from "./forms/ModalUserV2.vue";
import ChangeRoleUser from "./forms/ChangeRoleUser.vue";
export default {
  name: "TableUsers",
  components: {
    Customdefaultimage,
    UserFormModal,
    ChangeRoleUser
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          value: "name"
        },
        {
          text: "Telefono",
          value: "contactData.phone"
        },
        {
          text: "Role",
          value: "role"
        },
        {
          text: "Inmobiliaria",
          value: "contactData.realEstateName"
        },
        {
          text: "Estatus",
          value: "contactData.active"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters("root", {
      getInmoById: "getInmoById"
    })
  },
  methods: {
    getImg(item) {
      if (item.contactData?.media?.featured_image?.src) {
        return { src: item.contactData.media.featured_image.src };
      } else {
        return {
          src: `https://dummyimage.com/600x400/2c344b/fff&text=${item?.name
            .substring(0, 2)
            .toUpperCase()}`
        };
      }
    },
    getStatus(item) {
      if (
        typeof item.contactData?.active === "undefined" ||
        item.contactData?.active
      ) {
        return { text: "Activo", color: "success" };
      }
      return { text: "Inactivo", color: "red" };
    },
    getInmo(user) {
      const inmo = this.getInmoById(user.contactData?.real_estate_group_id);
      if (!inmo && user.role === "developer") {
        return "N/A";
      }
      if (!inmo) {
        return "Sin Inmobiliaria";
      }
      return inmo?.name;
    }
  }
};
</script>
