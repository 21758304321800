import request from "../helpers/request";

export function createPresentation(presentation) {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/create", presentation)
        .then(response => {
          resolve(response.data.presentation);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function getBrokerExternalListPresentation() {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/listBrex")
        .then(response => {
          resolve(response.data.brex);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function getAllPresentation() {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/all")
        .then(response => {
          resolve(response.data.listPre);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function getAllPresentationBroker() {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/broker/all")
        .then(response => {
          resolve(response.data.listPresentationBroker);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function getPresentaton(idPresentation) {
  try {
    if (!idPresentation)
      return Promise.reject("No se especificó el id de la presentación");
    let data = {
      idPresentation: idPresentation
    };
    return new Promise((resolve, reject) => {
      request("/presentation/detail", data)
        .then(response => {
          resolve(response.data.presentation);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function getStatusPresentationByGroupId() {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/stats")
        .then(response => {
          resolve(response.data.stats);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function deletePresentatonById(presentationId) {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/delete", { presentationId })
        .then(response => {
          resolve(response.data.stats);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function getAllDesarrollosByGroupId() {
  try {
    return new Promise((resolve, reject) => {
      request("/presentation/listDesarrollos")
        .then(response => {
          resolve(response.data.desarrollos);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
