import {
  RealEstateGroupDetail,
  UpdateRealEstateGroup
} from "@/api/real-estate-group-api.js";
export default {
  namespaced: true,
  state: {
    realEstateGroupLoading: false,
    realEstateGroup: null
  },
  getters: {},
  mutations: {
    setRealEstateGroupLoading(state, val) {
      state.realstateGroupLoading = val;
    },
    RealEstateGroupDetail(state, value) {
      state.realEstateGroup = value.RealEstateGroup;
    }
  },
  actions: {
    fecthRealEstateGroupDetail({ commit }) {
      commit("setRealEstateGroupLoading", true);
      try {
        return new Promise(resolve => {
          RealEstateGroupDetail()
            .then(res => {
              commit("RealEstateGroupDetail", res.data);
              commit("setRealEstateGroupLoading", false);
              resolve(res.data);
            })
            .catch(error => {
              commit("setRealEstateGroupLoading", false);
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateRealEstateGroup({ commit }, body) {
      try {
        return new Promise(resolve => {
          UpdateRealEstateGroup(body)
            .then(res => {
              commit("setRealEstateGroupLoading", false);
              resolve(res);
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
