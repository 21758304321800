import request from "../helpers/request";

const API_URL = process.env.VUE_APP_API_ENDPOINT;

// pasar esta funcion a nuevo formato con request helper
export function GetRealEstateDevelopmentList() {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  let access_group = payload.access_group;
  let body = JSON.stringify({
    access_group: access_group,
    order: {
      name: true,
      favorite: true
    }
  });

  return fetch(API_URL + "/RealEstateDevelopment/List", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken
    },
    body: body
  })
    .then(res => res.json())
    .then(json => json.Data);
}

// registra un nuevo desarrollo inmobiliario
export function CreateRealEstateDevelopment(realEstateDevelopment) {
  try {
    let data = {};

    data = {
      code: realEstateDevelopment.code,
      name: realEstateDevelopment.name,
      status: 1
    };

    return new Promise((resolve, reject) => {
      request("/realEstateDevelopment/create", data)
        .then(response => {
          resolve({
            RealEstateDevelopment: response.data.RealEstateDevelopment
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function getRealEstateDevAssgignApi() {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  try {
    return new Promise((resolve, reject) => {
      request("/user/RealEstateDevelopmentsAssigned", {
        userId: payload.sub,
        role: payload.role,
        contactId: payload.contact_id
      })
        .then(response => {
          resolve({
            response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

//obtener datos del desarollo
export async function GetRealEstateDevelopmentDetail(code) {
  try {
    let data = {
      real_estate_development_code: code
    };
    return new Promise((resolve, reject) => {
      request("/realEstateDevelopment/detail", data)
        .then(response => {
          resolve({
            RealEstateDevelopment: response.data.RealEstateDevelopment
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

// actualizar datos
export function UpdateRealEstateDevelopment(
  dataDevelopment,
  realEstateDevelopmentId
) {
  let newdata = {
    realEstateID: realEstateDevelopmentId,
    realEstate: dataDevelopment
  };
  try {
    return new Promise((resolve, reject) => {
      request("/RealEstateDevelopment/Update", newdata)
        .then(response => {
          resolve({
            response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

//Obtener los descuentos de un desarrollo

export function GetDiscountList(id) {
  try {
    const role = localStorage.getItem("user_role");
    if (!["admin", "developer", "coordinator"].includes(role))
      return Promise.reject("No tienes permisos para ver los descuentos");

    let data = {
      real_estate_developments_id: id
    };
    return new Promise((resolve, reject) => {
      request("/discount/list", data)
        .then(response => {
          resolve({
            Discounts: response.data.Discount
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}
