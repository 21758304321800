<template>
  <v-card color="basil">
    <v-toolbar :color="background" flat height="120">
      <v-img :src="getLogo()" contain height="100px"></v-img>
    </v-toolbar>

    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="item in items" :key="item.name">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.name">
        <component :is="item.component" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import RootLayout from "@/components/layouts/RootLayout.vue";
import DetailByInmo from "@/components/root/DetailByInmo.vue";
import DevelopmentsByInmo from "../../components/root/DevelopmentsByInmo.vue";
import UsersByInmo from "../../components/root/UsersByInmo.vue";
import CarouselsByInmo from "../../components/root/CarouselsByInmo.vue";
import LeadsByInmoVue from "../../components/root/LeadsByInmo.vue";
export default {
  name: "RealEstateGroupDetail",
  components: {
    DevelopmentsByInmo,
    UsersByInmo,
    CarouselsByInmo,
    DetailByInmo,
    LeadsByInmoVue
  },
  data() {
    return {
      background: "blue-grey lighten-4",
      tab: null,
      items: [
        {
          name: "General/Config",
          component: "DetailByInmo"
        },
        {
          name: "Developments",
          component: "DevelopmentsByInmo"
        },
        {
          name: "Users",
          component: "UsersByInmo"
        },
        {
          name: "Carruseles",
          component: "CarouselsByInmo"
        },
        {
          name: "Leads",
          component: "LeadsByInmoVue"
        }
      ]
    };
  },
  created() {
    this.$emit(`update:layout`, RootLayout);
  },
  mounted() {
    const id = this.$route.params.id;
    this.fetchInmoInspect(id);
  },
  computed: {
    ...mapGetters("root", {
      inmo: "getInmoInspect"
    })
  },
  methods: {
    ...mapMutations("root", {
      setInmoInspect: "setInmoInspect"
    }),
    ...mapActions("root", {
      fetchInmoInspect: "fetchInmoInspect"
    }),
    getLogo() {
      if (this.inmo.media?.logo?.src) {
        return this.inmo.media.logo.src;
      }
      return require("@/assets/logoC28_primary.png");
    }
  },
  beforeDestroy() {
    this.setInmoInspect({});
  }
};
</script>
