export default function AdminMiddleware({ next, router }) {
  try {
    let userToken = localStorage.getItem("token");
    let userRole = localStorage.getItem("user_role");
    if (!userToken) {
      return router.push({ name: "login" });
    }
    if (userRole === "admin") {
      return next();
    } else {
      return router.push({ name: "login" });
    }
  } catch (err) {
    return router.push({ name: "login" });
  }
}
