import request from "../helpers/request";

export function ReportCreateColumn(body) {
  return new Promise((resolve, reject) => {
    request("/reports/create", body)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}
export function ReportGetRowColumn() {
  return new Promise((resolve, reject) => {
    request("/reports/list", {})
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function ReportUpdateColumn(body) {
  return new Promise((resolve, reject) => {
    request("/reports/update", body)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}
export function getDataByComertialReport(filter) {
  try {
    return request("/lead/report/comertial", filter);
  } catch (err) {
    return err;
  }
}

export function fetchReportDetail(typeReport, filter) {
  return request(`/lead/report/${typeReport}/detail`, filter);
}

//ANUAL MKT
export async function fetchListLeadsInAnualMKT(filter) {
  try {
    return request("/lead/report/anualMkt", filter);
  } catch (err) {
    return err;
  }
}

export async function getDataByCampaignReport(filter) {
  try {
    return request("/lead/report/campaing", filter);
  } catch (err) {
    return err;
  }
}

// campañas
export async function fetchCampaigns() {
  try {
    return request("/traffic/campaigns");
  } catch (err) {
    return err;
  }
}
