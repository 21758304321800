import request from "../helpers/request";

//Obtener los brokers externos..
export function getExternalBroker() {
  try {
    const data = {
      type: "external"
    };
    return new Promise((resolve, reject) => {
      request("/ContactBroker/List", data)
        .then(response => {
          resolve(response.data.ContactBroker);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
//Crear broker externo..
export function createBrokerExternal(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/contactBrokers/createBroker", data)
        .then(response => {
          resolve(response.data.contactBrokers);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
//Buscar broker externo por su telefono...
//Aunque no funciona...
//Verificar que si funcione...
export function searchByRepeated(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/ContactBroker/getExternalBrokerDetail", data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

//Nuevo end point para editar el broker externo..
export function editExternalBroker(data) {
  return request("/ContactBroker/updateExternalBroker", data);
}
//Busqueda de broker externo..
export function searchExternalBroker(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/ContactBroker/searchExternalBroker", data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
//Convertir de lead a broker externo..
export function transformExternalBroker(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/ContactBroker/createExternalBrokerFromLead", data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
