<template>
  <v-data-iterator :items="developments" :search="search" :loading="loading">
    <template v-slot:loading>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            color="primary"
            class="mt-5"
          ></v-progress-linear>
          <p>Cargando desarrollos...</p>
        </v-col>
      </v-row>
    </template>
    <template v-slot:default="props">
      <section class="container__developments">
        <CardDevelopment
          v-for="item in props.items"
          :key="item._id"
          :development="item"
        ></CardDevelopment>
      </section>
    </template>
  </v-data-iterator>
</template>

<script>
import CardDevelopment from "./CardDevelopment.vue";
export default {
  name: "GridDevelopments",
  components: {
    CardDevelopment
  },
  props: {
    developments: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.container__developments {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 1rem;
}
</style>
