import AdminMiddleware from "../middlewares/admin-middleware";
const slug = "/admin";
const role = "Admin";

const routes = [
  // momentaneo
  {
    path: "*",
    redirect: `${slug}/leads`,
    name: role
  },
  /* Broker externos */
  {
    path: `${slug}/external-broker/home`,
    name: `${role}ExternalBroker`,
    component: () => import("../views/external-broker/AdminExternalBrokerView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Brokers Externos"
    }
  },
  /* Merge Broker externos */
  {
    path: `${slug}/external-broker/cuau`,
    name: `${role}MergeExternalBroker`,
    component: () =>
      import("../views/external-broker/AdminMergeExternalBrokerView.vue"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Brokers Externos"
    }
  },
  /* Desarrollos */
  {
    path: `${slug}/real-estate-development/list`,
    name: `${role}RealEstateDevelopmentView`,
    component: () =>
      import("../views/real-estate-development/AdminRealEstateDevelopmentView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Desarrollos Inmobiliarios"
    }
  },
  {
    path: `${slug}/real-estate-development/create`,
    name: `${role}RealEstateDevelopmentCreateView`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentCreateView"
      ),
    meta: {
      middleware: [AdminMiddleware],
      title: "Nuevo Desarrollo Inmobiliario"
    }
  },
  {
    path: `${slug}/real-estate-development/edit/:code`,
    name: `${role}RealEstateDevelopmentEditView`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentEditView"
      ),
    meta: {
      middleware: [AdminMiddleware],
      title: "Editar Desarrollo Inmobiliario"
    }
  },
  /* Cotizador */
  {
    path: `${slug}/real-estate-development/quote/:code`,
    name: `${role}RealEstateDevelopmentQuoteView`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentQuoteView"
      ),
    meta: {
      middleware: [AdminMiddleware],
      title: "Cotizador"
    }
  },

  /* Cotizador con propiedad */
  {
    path: `${slug}/real-estate-development/quote/:code/:property`,
    name: `${role}RealEstateDevelopmentQuotePropertyView`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentQuoteView"
      ),
    meta: {
      middleware: [AdminMiddleware],
      title: "Cotizador"
    }
  },

  /* Nuevo Cotizador avanzado.. */
  {
    path: `${slug}/real-estate-development/quoteAdvanced/:code`,
    name: `${role}RealEstateDevelopmentQuotePropertyViewAdvanced`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentQuoteAdvancedView"
      ),
    meta: {
      middleware: [AdminMiddleware],
      title: "Cotizador"
    }
  },
  /* Nuevo Cotizador avanzado con propiedad seleccionada.. */
  {
    path: `${slug}/real-estate-development/quoteAdvanced/:code/:property`,
    name: `${role}RealEstateDevelopmentQuotePropertyViewSelected`,
    component: () =>
      import(
        "../views/real-estate-development/AdminRealEstateDevelopmentQuoteAdvancedView"
      ),
    meta: {
      middleware: [AdminMiddleware],
      title: "Cotizador"
    }
  },

  /* Propiedades */
  {
    path: `${slug}/property/list`,
    name: `${role}PropertyView`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Propiedades"
    }
  },
  {
    path: `${slug}/:realEstateCode/property/list`,
    name: `${role}PropertyViewCode`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Propiedades"
    }
  },
  /** Users - Contacts */
  {
    path: `${slug}/contact/list`,
    name: `${role}ContactView`,
    component: () => import("../views/users-contact/AdminContactView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Asesores inmobiliarios"
    }
  },
  /* Prospectos - Leads */
  {
    path: `${slug}/leads`,
    name: `${role}LeadsView`,
    component: () => import("../views/lead/AdminLeads"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/list`,
    name: `${role}LeadListView`,
    component: () => import("../views/lead/AdminLeadView"),
    redirect: `${slug}/leads`,
    meta: {
      middleware: [AdminMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/kanban`,
    name: `${role}LeadKanbanView`,
    component: () => import("../views/lead/AdminLeadKanbanView"),
    redirect: `${slug}/leads`,
    meta: {
      middleware: [AdminMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/reports`,
    name: `${role}CampaignView`,
    component: () => import("../views/reports-campaings/TableReportKpis.vue"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Reportes"
    }
  },
  /* Manifest Log */
  {
    path: `${slug}/manifest/recent-comments`,
    name: `${role}ManifestLogView`,
    component: () => import("../views/manifest-log/AdminManifestLogView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Comentarios recientes"
    }
  },
  {
    path: `${slug}/contacts/list`,
    name: `${role}ContactsView`,
    component: () => import("../views/contact/AdminContactsView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Asesores"
    }
  },
  {
    path: `${slug}/contact/assign`,
    name: `${role}ContactsAssignView`,
    component: () => import("../views/contact/AdminContactsAssignView"),
    meta: {
      middleware: [AdminMiddleware],
      title: "Asignación"
    }
  }
];

export default routes;
