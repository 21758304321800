<template>
  <tr>
    <td>
      {{ carrousel.name }}
    </td>
    <td class="text-xs-right">
      {{ carrousel.contact_list.length }}
    </td>
    <td class="text-xs-right">{{ created_at }}</td>
    <td
      :class="[
        'text-xs-right font-weight-bold',
        `${getColorLastAsignation}--text `
      ]"
    >
      {{ lastAsigned }}
    </td>
    <td class="text-xs-right">
      <v-chip :class="getStatus.color" dark small> {{ getStatus.text }}</v-chip>
    </td>
    <td>
      <v-chip color="primary">{{ getTypeAsignation() }}</v-chip>
    </td>
    <td>
      <ModalCarrousel :carrousel="carrousel" />
      <v-btn @click="clickDelete" icon color="primary">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import { getTypeAsignation, getStatus } from "../utils/scripts/carrousel";
import ModalCarrousel from "./forms/ModalCarrousel.vue";
export default {
  name: "ItemCarrusel",
  components: {
    ModalCarrousel
  },
  props: {
    carrousel: {
      type: Object,
      required: true,
      default: () => {}
    },
    maxWidth: {
      type: String,
      default: "500"
    },
    minWidth: {
      type: String,
      default: "350"
    }
  },
  data() {
    return {
      inmoId: this.$route.params.id,
      confirm: false
    };
  },
  computed: {
    getStatus() {
      return getStatus(this.carrousel);
    },
    getColorLastAsignation() {
      // si desde date_assigned a pasado 1 mes o mas, se pone en rojo
      // si no, se pone en verde
      const oneDay = 24 * 60 * 60 * 1000;
      const dateAssigned = new Date(this.carrousel.date_assigned);
      const dateNow = new Date();
      const diff = dateNow - dateAssigned;
      const diffInDays = diff / oneDay;

      switch (true) {
        case diffInDays >= 30:
          return "red";
        case diffInDays >= 15:
          return "orange";
        default:
          return "green";
      }
    },
    lastAsigned() {
      return this.carrousel.date_assigned
        ? new Date(this.carrousel.date_assigned).toLocaleDateString()
        : "N/A";
    },
    created_at() {
      return this.carrousel.created_at
        ? new Date(this.carrousel.created_at).toLocaleDateString()
        : "N/A";
    }
  },
  methods: {
    ...mapActions("root", {
      deleteCarousel: "deleteCarouselByinmo"
    }),
    getTypeAsignation() {
      return getTypeAsignation(this.carrousel);
    },
    async deleteCar() {
      const res = await this.deleteCarousel({
        inmoId: this.inmoId,
        carouselId: this.carrousel._id
      });

      if (!res.data)
        return this.$snotify.error(
          "Hubo un error al eliminar el carrusel",
          "Error :|"
        );
      this.$snotify.success("Carrusel eliminado correctamente", "Success");
    },
    clickDelete() {
      this.$confirmation(
        "Eliminar Carrusel",
        "¿Estas seguro que deseas eliminar este carrusel?",
        () => this.deleteCar()
      );
    }
  }
};
</script>

<style></style>
