var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre","hint":"Nombre de la Inmobiliaria.","persistent-hint":"","readonly":!_vm.edit},on:{"input":function($event){return _vm.changeDataEdit('name', $event)}},model:{value:(_vm.mockInmo.name),callback:function ($$v) {_vm.$set(_vm.mockInmo, "name", $$v)},expression:"mockInmo.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"label":"Fases ocultas","hint":"Las fases ocultas no apareceran en los Leads ni en los reportes.","persistent-hint":"","readonly":!_vm.edit,"multiple":"","items":_vm.phases},on:{"input":function($event){return _vm.changeDataEdit('real_estate_phase', $event)}},model:{value:(_vm.mockInmo.real_estate_phase),callback:function ($$v) {_vm.$set(_vm.mockInmo, "real_estate_phase", $$v)},expression:"mockInmo.real_estate_phase"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"disabled":"","label":"Estado","hint":"Deprecado, verificar que impacto tiene en el sistema","persistent-hint":"","readonly":!_vm.edit,"items":[
          { text: 'Activo', value: 1 },
          { text: 'Inactivo', value: 2 }
        ]},on:{"input":function($event){return _vm.changeDataEdit('status', $event)}},model:{value:(_vm.mockInmo.status),callback:function ($$v) {_vm.$set(_vm.mockInmo, "status", $$v)},expression:"mockInmo.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"label":"Activo","hint":"Cuando una Inmo se desactiva, siguien llegando Leads,\n                    pero los usuario no pueden acceder a su\n                    cuenta.","persistent-hint":"","readonly":!_vm.edit,"items":[
          { text: 'Si', value: true },
          { text: 'No', value: false }
        ]},on:{"input":function($event){return _vm.changeDataEdit('active', $event)}},model:{value:(_vm.mockInmo.active),callback:function ($$v) {_vm.$set(_vm.mockInmo, "active", $$v)},expression:"mockInmo.active"}})],1)],1),_c('v-list',[_c('v-subheader',[_vm._v("Asignacion")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Metodo de Asignacion")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Carrusel: Los Leads se asignaran de manera automatica a los asesores. "),_c('br'),_vm._v(" Manual: Los Leads entraran con la fase de Por Asignar. ")])],1),_c('v-list-item-action',[_c('v-select',{attrs:{"readonly":!_vm.edit,"items":[
            { text: 'Carrusel', value: 1 },
            { text: 'Manual', value: 0 }
          ]},on:{"input":function($event){return _vm.changeDataEdit('assignment_method', $event)}},model:{value:(_vm.mockInmo.assignment_method),callback:function ($$v) {_vm.$set(_vm.mockInmo, "assignment_method", $$v)},expression:"mockInmo.assignment_method"}})],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"readonly":!_vm.edit,"true-value":"on","false-value":"off"},on:{"change":event => _vm.changeDataEdit('general_active', event)},model:{value:(_vm.mockInmo.general_active),callback:function ($$v) {_vm.$set(_vm.mockInmo, "general_active", $$v)},expression:"mockInmo.general_active"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Carrusel General")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Si esta activo, todos los Leads que no entren por un Carrusel especifico, entraran por defecto a este Carrusel. ")])],1)],1),_c('v-subheader',[_vm._v("Cotizador")]),_c('v-list-item',[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"readonly":!_vm.edit,"true-value":"on","false-value":"off"},on:{"change":event => _vm.changeDataEdit('moduleQuote', event)},model:{value:(_vm.mockInmo.moduleQuote),callback:function ($$v) {_vm.$set(_vm.mockInmo, "moduleQuote", $$v)},expression:"mockInmo.moduleQuote"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Habilitar cotizador")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Activar el modulo de cotizador para esta inmobiliaria ")])],1)]],2),_c('v-subheader',[_vm._v("Duplicados")]),_c('v-list-item',[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"readonly":!_vm.edit,"true-value":"on","false-value":"off"},on:{"change":event =>
                _vm.changeDataEdit(
                  'activate_duplicate_contact_lead_search',
                  event
                )},model:{value:(_vm.mockInmo.activate_duplicate_contact_lead_search),callback:function ($$v) {_vm.$set(_vm.mockInmo, "activate_duplicate_contact_lead_search", $$v)},expression:"mockInmo.activate_duplicate_contact_lead_search"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Buscar Leads duplicados")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Si esta activo, el sistema buscara duplicados en los Leads que entren a esta inmobiliaria. ")])],1)]],2),_c('v-list-item',[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"readonly":!_vm.edit,"true-value":"on","false-value":"off"},on:{"change":event => _vm.changeDataEdit('search_for_duplicate_names', event)},model:{value:(_vm.mockInmo.search_for_duplicate_names),callback:function ($$v) {_vm.$set(_vm.mockInmo, "search_for_duplicate_names", $$v)},expression:"mockInmo.search_for_duplicate_names"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Buscar duplicados por nombre")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Si esta activo, el sistema buscara duplicados por nombre. Por defecto, el sistema solo busca duplicados por correo y telefono. ")])],1)]],2),_c('v-list-item',[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"readonly":!_vm.edit,"true-value":"on","false-value":"off"},on:{"change":event =>
                _vm.changeDataEdit(
                  'search_duplicates_just_one_value_carrousel',
                  event
                )},model:{value:(_vm.mockInmo.search_duplicates_just_one_value_carrousel),callback:function ($$v) {_vm.$set(_vm.mockInmo, "search_duplicates_just_one_value_carrousel", $$v)},expression:"mockInmo.search_duplicates_just_one_value_carrousel"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Tomar en cuenta solo un campo, como duplicado")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Si esta activo, el sistema considerara a un Lead como duplicado si alguno de sus campos coincide con el de otro Lead. Por defecto, el sistema considera duplicado si coinciden el correo, telefono y el nombre (si esta activo). ")])],1)]],2),_c('v-subheader',[_vm._v("Otros")]),_c('v-list-item',[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"readonly":!_vm.edit,"true-value":"on","false-value":"off"},on:{"change":event => _vm.changeDataEdit('view_all_development', event)},model:{value:(_vm.mockInmo.view_all_development),callback:function ($$v) {_vm.$set(_vm.mockInmo, "view_all_development", $$v)},expression:"mockInmo.view_all_development"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ver todos los desarrollos")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" Si esta activo, los roles de admin, broker y coordinator podran ver todos los desarrollos de esta inmobiliaria. ")])],1)]],2)],1),_c('section',{staticClass:"d-flex justify-end"},[(!_vm.edit)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.edit = true}}},[_vm._v("Editar")]):_vm._e(),(_vm.edit)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":_vm.update}},[_vm._v("Actualizar")]):_vm._e(),(_vm.edit)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"error"},on:{"click":_vm.cancelEdit}},[_vm._v("Cancelar")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }