import { render, staticRenderFns } from "./GridDevelopments.vue?vue&type=template&id=47c5a749&scoped=true&"
import script from "./GridDevelopments.vue?vue&type=script&lang=js&"
export * from "./GridDevelopments.vue?vue&type=script&lang=js&"
import style0 from "./GridDevelopments.vue?vue&type=style&index=0&id=47c5a749&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c5a749",
  null
  
)

export default component.exports