<template>
  <v-menu offset-y max-height="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation class="body-2 text-capitalize" v-bind="attrs" v-on="on">
        {{ name }}
        <v-icon small>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class="option"
        v-for="(item, index) in roles"
        :key="index"
        @click="selectRole(item.value)"
      >
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getRoles } from "../common/utils/constants";
export default {
  name: "SelectableRole",
  props: {
    value: {
      type: String,
      required: false
    },
    ommits: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      roles: []
    };
  },
  mounted() {
    this.setRoles();
  },
  computed: {
    name() {
      const role = this.roles.find(role => role.value === this.value);
      return role ? role.text : "Seleccionar rol";
    }
  },
  methods: {
    selectRole(role) {
      this.$emit("input", role);
    },
    setRoles() {
      this.roles = getRoles(this.ommits);
    }
  }
};
</script>

<style scoped>
.option {
  cursor: pointer;
}

.option:hover {
  background-color: #f5f5f5;
}
</style>
