import {
  createPresentation,
  getBrokerExternalListPresentation,
  getAllPresentation,
  getAllPresentationBroker,
  getPresentaton,
  getStatusPresentationByGroupId,
  deletePresentatonById,
  getAllDesarrollosByGroupId
} from "@/api/presentation-api.js";
export default {
  namespaced: true,
  state() {
    return {
      contactList: null,
      contactListAllocation: null,
      contactListLoading: false,
      multiAssigned: false,
      brokerExternalList: [],
      allPresentation: [],
      allPresentationBroker: [],
      allDesarrollos: [],
      presentation: null,
      stats: null
    };
  },
  getters: {
    brokerExternalList: state => state.brokerExternalList,
    allPresentation: state => state.allPresentation,
    allPresentationBroker: state => state.allPresentationBroker,
    presentation: state => state.presentation,
    allDesarrollos: state => state.allDesarrollos,
    stats: state => state.stats
  },
  mutations: {
    async set_brokerExternalList(state, brokerExternalList) {
      state.brokerExternalList = brokerExternalList;
    },
    async addBrokerExternalToList(state, brokerExterno) {
      state.brokerExternalList.push(brokerExterno);
    },
    async set_allPresentation(state, allPresentation) {
      state.allPresentation = allPresentation;
    },
    async set_allPresentationBroker(state, allPresentationBroker) {
      state.allPresentationBroker = allPresentationBroker;
    },
    async set_presentation(state, presentation) {
      state.presentation = presentation;
    },
    async set_stats(state, stats) {
      state.stats = stats;
    },
    async set_allDesarrollos(state, desarrollos) {
      state.allDesarrollos = desarrollos;
    },
    async set_data_presentations(state, presentationId) {
      state.allPresentation.forEach(item => {
        // Buscar el índice del objeto de presentación que queremos eliminar
        const index = item.presentations.findIndex(
          presentation => presentation._id === presentationId
        );
        // Si se encuentra el objeto (es decir, findIndex no devuelve -1), eliminarlo del array
        if (index !== -1) {
          item.presentations.splice(index, 1);
        }
      });
      //Array de los brokers
      // Buscar el índice del objeto de presentación que queremos eliminar
      const index = state.allPresentationBroker.findIndex(
        presentation => presentation._id === presentationId
      );
      // Si se encuentra el objeto (es decir, findIndex no devuelve -1), eliminarlo del array
      if (index !== -1) {
        state.allPresentationBroker.splice(index, 1);
      }
    }
  },
  actions: {
    async getBrokerExternalList({ commit }) {
      //commit("setContactListLoading", true);
      try {
        const externalBrokerPresentation = await getBrokerExternalListPresentation();
        //commit("algo", externalBrokerPresentation);
        commit("set_brokerExternalList", externalBrokerPresentation);
        return externalBrokerPresentation;
      } catch (error) {
        //commit("setContactListLoading", false);
        return Promise.reject(error);
      }
    },
    async addBrokerExternalToList({ commit }, brokerExteno) {
      commit("addBrokerExternalToList", brokerExteno);
    },
    async getAllPresentationByGroup({ commit }) {
      try {
        const allPresentation = await getAllPresentation();
        commit("set_allPresentation", allPresentation);
        return allPresentation;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAllPresentationByBroker({ commit }) {
      try {
        const allPresentationBroker = await getAllPresentationBroker();
        commit("set_allPresentationBroker", allPresentationBroker);
        return allPresentationBroker;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createNewPresentation({ commit }, presentation) {
      try {
        const newPresentation = await createPresentation(presentation);
        commit("addNewPresentation", newPresentation);
        return newPresentation;
      } catch (error) {
        return false;
      }
    },
    async getPresentationById({ commit }, idPresentation) {
      try {
        const presentationActual = await getPresentaton(idPresentation);
        commit("set_presentation", presentationActual);
        return presentationActual;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getStatsPresentationByGroup({ commit }) {
      try {
        const stats = await getStatusPresentationByGroupId();
        commit("set_stats", stats);
        return stats;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deletePresentation({ commit }, presentationId) {
      try {
        const newStats = await deletePresentatonById(presentationId);
        commit("set_data_presentations", presentationId);
        commit("set_stats", newStats);
        return newStats;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAllDesarrollosByGroup({ commit }) {
      try {
        const allDesarrollos = await getAllDesarrollosByGroupId();
        commit("set_allDesarrollos", allDesarrollos);
        return allDesarrollos;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
