<template>
  <div>
    <v-form ref="form" @submit.prevent="handleSubmit">
      <v-img :src="logo" />
      <v-text-field
        label="Usuario"
        name="login"
        prepend-icon="mdi-account"
        type="text"
        v-model="email"
        :rules="emailRules"
      />
      <v-text-field
        id="password"
        label="Contraseña"
        name="password"
        prepend-icon="mdi-lock"
        type="password"
        v-model="password"
        :rules="passwordRules"
      />
      <v-alert v-show="messageError" outlined type="error" border="left" text>
        {{ messageError }}
      </v-alert>

      <v-btn :disabled="loading" :loading="loading">
        Login
        <template v-slot:loader>
          <v-img :src="`${publicPath}c28-animation-2.gif`"> </v-img>
        </template>
      </v-btn>
    </v-form>
    <v-img :src="getLoginImage()"></v-img>
  </div>
</template>

<script>
import { LogIn, IsLogged } from "@/api/user-api.js";
import BlankLayout from "@/components/layouts/BlankLayout.vue";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      email: "",
      password: "",
      messageError: "",
      showLoginForm: false,
      logo: require("@/assets/logo-capital28-blanco.png"),
      loading: false,
      submitted: false,
      emailRules: [
        v => !!v || "Ingrese su correo",
        v => /.+@.+/.test(v) || "Correo inválido"
      ],
      passwordRules: [v => !!v || "Ingrese su contraseña"]
    };
  },
  created() {
    this.$emit(`update:layout`, BlankLayout);
    // checar si el usuario esta logueado
    this.isLoggedUser();
  },
  methods: {
    // listeners
    handleSubmit() {
      this.submitted = true;
      if (!this.loading) {
        if (this.$refs.form.validate()) {
          this.login();
        }
      }
    },
    async login() {
      let self = this;
      self.loading = true;
      self.messageError = "";

      await LogIn(self.email, self.password)
        .then(res => {
          if (res.Role) self.redirectDasboard(res.Role);
        })
        .catch(err => {
          if (err) self.messageError = "Credenciales no válidas";
        })
        .finally(() => {
          self.loading = false;
        });
    },
    redirectDasboard(role) {
      this.$router.push({ path: `${role}/` });
    },
    isLoggedUser() {
      try {
        let self = this;
        let role = IsLogged().Role;
        if (role) self.redirectDasboard(role);
      } catch (err) {
        // report err
      }
    },
    getLoginImage() {
      return `${this.publicPath}img/undraw_house_searching_n8mp.svg`;
    }
  }
};
</script>
<style>
/* 
primary: #262d3c
secondary: #60a6a4
*/
.card__logins {
  background-color: #5b81d35c !important;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
</style>
