<template>
  <div>
    <v-btn color="primary" dark @click.native.stop="dialog = true"
      >Crear Carrusel</v-btn
    >
    <v-dialog v-model="dialog" max-width="700">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :rules="[() => !(type === '' && e1 > 1)]"
            :complete="e1 > 1"
            step="1"
          >
            Tipo de Carrusel
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            Datos de asignación
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Contactos
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              elevation="0"
              class="mb-5 px-4"
              min-height="200px"
              color="grey lighten-5"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    :items="types"
                    v-model="type"
                    label="Tipo de Carrusel"
                    hint="Selecciona el tipo de carrusel"
                    persistent-hint
                  ></v-select>
                  <v-card-text
                    class="
                  text--secondary
                  caption
                  pb-0
                  "
                  >
                    <ul>
                      <li>
                        <strong>Por Desarrollo:</strong> Se asignarán los Leads
                        que esten interesados en el desarrollo seleccionado.
                      </li>
                      <li>
                        <strong>Por Campaña:</strong>Se asignarán los Leads que
                        entrarón a través de la campaña o campañas
                        seleccionadas.
                      </li>
                      <li>
                        <strong>General:</strong> Se asignarán los Leads que no
                        cumplan con las condiciones anteriores.
                      </li>
                    </ul>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>

            <v-btn color="primary" :disabled="!type" @click="e1 = 2">
              Continue
            </v-btn>

            <v-btn text @click="dialog = false">
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              elevation="0"
              class="mb-5 px-4"
              min-height="200px"
              color="grey lighten-5"
            >
              <v-row v-if="type === 'campaign'">
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Nombre de la campaña"
                    hint="El nombre del carrusel debe ser el mismo que el de la campaña"
                    persistent-hint
                    v-model="name"
                    required
                    @change="searchNameCarrusel"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Campañas"
                    hint="Añade el nombre de las campañas que se asignarán al carrusel"
                    persistent-hint
                    required
                    v-model="tag"
                  >
                    <template v-slot:append>
                      <v-icon color="primary" :disabled="!tag" @click="addTag()"
                        >mdi-plus</v-icon
                      >
                    </template>
                  </v-text-field>
                  <v-chip v-for="(tag, index) in name_aliases" :key="index">{{
                    tag
                  }}</v-chip>
                </v-col>
              </v-row>
              <v-row v-if="type === 'development'">
                <v-col>
                  <v-autocomplete
                    label="Seleciona el desarrollo"
                    :items="devsByInmo"
                    item-text="name"
                    v-model="devSelect"
                    :item-value="
                      item => {
                        return { _id: item._id, name: item.name };
                      }
                    "
                    hint="*Por defecto el nombre del carrusel es el mismo que el del desarrollo"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="type === 'general'">
                <v-col cols="12" sm="12">
                  <v-text-field
                    readonly
                    label="Nombre del carrusel"
                    value="General"
                    hint="*El nombre por defecto es General"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>

            <v-btn
              :disabled="!validSecondStage"
              color="primary"
              @click="e1 = 3"
            >
              Continue
            </v-btn>

            <v-btn text @click="e1--">
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card
              elevation="0"
              class="mb-5 px-4"
              min-height="200px"
              color="grey lighten-5"
            >
              <v-autocomplete
                label="Contactos"
                :items="usersByInmo"
                v-model="contact_list"
                item-text="name"
                item-value="contact_id"
                hint="Selecciona los contactos que se asignarán al carrusel"
                persistent-hint
                multiple
                chips
                small-chips
                deletable-chips
                clearable
              ></v-autocomplete>
            </v-card>
            <v-btn
              :disabled="contact_list.length === 0"
              color="primary"
              @click="buildCarrusel()"
            >
              Crear carrusel
            </v-btn>
            <v-btn text @click="e1--">
              Cancel
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { searchCampaign } from "../../../api/mnt-api";
export default {
  name: "CreateCarrousel",
  data() {
    return {
      dialog: false,
      e1: 1,
      type: "",
      name: "",
      devSelect: {},
      name_aliases: [],
      contact_list: [],
      tag: "",
      types: [
        {
          text: "Por Desarrollo",
          value: "development"
        },
        {
          text: "Por Campaña",
          value: "campaign"
        },
        {
          text: "General",
          value: "general"
        }
      ],
      inmoId: this.$route.params.id,
      validSecondStage: false
    };
  },
  mounted() {
    if (this.usersByInmo.length === 0) this.fetchUsersByInmo(this.inmoId);
  },
  computed: {
    ...mapGetters("root", {
      devsByInmo: "getDevelopmentsByInmoInspect",
      usersByInmo: "getUsersByInmoInspect"
    })
  },
  watch: {
    async name(val) {
      const matches = await this.searchNameCarrusel(val);
      if (matches === 0) {
        this.validSecondStage = true;
        return;
      }
      this.validSecondStage = false;
      this.$snotify.error("Ya existe un carrusel con ese nombre", "Duplicado");
    },
    type(val) {
      if (val === "general") this.name = "General";
      if (val === "development" && this.devsByInmo.length === 0)
        this.fetchDevelopmentsByInmo(this.inmoId);
    },
    devSelect() {
      this.name = this.devSelect.name;
    }
  },
  methods: {
    ...mapActions("root", {
      createCarrusel: "createCarrusel",
      fetchDevelopmentsByInmo: "fetchDevelopmentsByInmo",
      fetchUsersByInmo: "fetchUsersByInmo"
    }),
    async addTag() {
      if (!this.tag) return;
      const matches = await this.searchNameCarrusel(this.tag);
      if (matches) {
        this.$snotify.error(
          "Ya existe un carrusel con esa campaña",
          "coincidencia"
        );
        return;
      }
      this.name_aliases.push(this.tag);
      this.tag = "";
    },
    async searchNameCarrusel(name) {
      const id = this.$route.params.id;
      const body = {
        name,
        type: this.type,
        developId: this.devSelect._id
      };
      const res = await searchCampaign(id, body);

      if (!res.data === undefined)
        return this.$snotify.error("Error al buscar coinsidencias", "Error");
      return res.data;
    },
    async buildCarrusel() {
      const factory = {
        development: this.createDevelopment,
        campaign: this.createCampaign,
        general: this.createGeneral
      };
      const data = factory[this.type]();
      const res = await this.createCarrusel({ carrusel: data });

      if (res) {
        this.$snotify.success("Carrusel creado");
        this.dialog = false;
      } else {
        this.$snotify.error("Error al crear el carrusel");
      }
    },
    createDevelopment() {
      return {
        name: this.devSelect.name,
        development: this.devSelect._id,
        type: this.type,
        contact_list: this.contact_list
      };
    },
    createCampaign() {
      return {
        name: this.name,
        name_aliases: this.name_aliases,
        type: this.type,
        contact_list: this.contact_list
      };
    },
    createGeneral() {
      return {
        name: "General",
        type: this.type,
        contact_list: this.contact_list
      };
    }
  }
};
</script>

<style></style>
