import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import AdminRoutes from "../router/admin-routes";
import BrokerRoutes from "../router/broker-routes";
import DeveloperRoutes from "../router/developer-routes";
import MarketingRoutes from "../router/marketing-routes";
import MarketingV2Routes from "../router/marketing-v2-routes";
import RootRoutes from "../router/root-routes";
import CoordinatorRoutes from "../router/coordinator-routes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/",
    redirect: "/admin/lead/list",
    name: "Home"
  },
  ...AdminRoutes,
  ...BrokerRoutes,
  ...DeveloperRoutes,
  ...MarketingRoutes,
  ...MarketingV2Routes,
  ...RootRoutes,
  ...CoordinatorRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // permitir slug parecido resalte menu
  // lead/list y lead/kanban resaltará el menu leads
  linkActiveClass: "active",
  linkExactActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to
    };
    // https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.title);
    if (nearestWithTitle) {
      document.title = `Capital28 | ${nearestWithTitle.meta.title}`;
    }

    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

export default router;
