import request from "../helpers/request";

const API_URL = process.env.VUE_APP_API_ENDPOINT;

export function GetContactLeadList() {
  const userToken = localStorage.getItem("token");

  return fetch(API_URL + "/ContactLead/getContactLead", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken
    },
    body: JSON.stringify({
      role: "admin"
    })
  })
    .then(res => res.json())
    .then(json => json.Data);
}

export function CreateContactLead(contactLead) {
  try {
    let data = {};
    let location = "nacional";
    if (contactLead.location) location = contactLead.location;
    data = {
      name: contactLead.name,
      email: contactLead.email,
      phone: contactLead.phone,
      location: location,
      allowDuplicate: 1
    };

    return new Promise((resolve, reject) => {
      request("/contactLead/create", data)
        .then(response => {
          resolve({
            ContactLead: response.data.ContactLead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export function UpdateContactLead(contactLeadId, updateData, leadId) {
  try {
    if (!contactLeadId) return Promise.reject("No se especificó contactLeadId");

    let data = {
      lead_id: leadId,
      contact_lead_id: contactLeadId,
      contact_lead: {}
    };

    if (updateData.name && updateData.name.length > 0) {
      data.contact_lead.name = updateData.name;
    }
    // usar typeof !== "undefined" cuando campo puede ser un string vacio
    if (typeof updateData.email !== "undefined") {
      data.contact_lead.email = updateData.email;
    }
    if (typeof updateData.phone !== "undefined") {
      data.contact_lead.phone = updateData.phone;
    }
    if (typeof updateData.location !== "undefined") {
      data.contact_lead.location = updateData.location;
    }

    return new Promise((resolve, reject) => {
      request("/contactLead/update", data)
        .then(response => {
          resolve({
            ContactLead: response.data.ContactLead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export function searchLeadContact(contactLead) {
  try {
    const data = {
      search: contactLead
    };

    return new Promise((resolve, reject) => {
      request("/lead/searchLeadContact", data)
        .then(response => {
          resolve({
            ContactLead: response.data.Lead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export function findDuplicateValues(leadData) {
  try {
    let data = {};
    data = {
      zones: leadData.zones,
      phone: leadData.phone,
      email: leadData.email
    };
    return new Promise((resolve, reject) => {
      request("/contactLead/findDuplicateValues", data)
        .then(response => {
          resolve({
            respuesta: response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
