import Vue from "vue";
import ConfirmationModal from "../components/common/Confirmation.vue";

const ConfirmationModalConstructor = Vue.extend(ConfirmationModal);

const createConfirmationModalInstance = vuetify => {
  const instance = new ConfirmationModalConstructor({
    el: document.createElement("div"),
    vuetify // Usa la instancia de Vuetify existente
  });

  document.body.appendChild(instance.$el);

  return instance;
};

const ConfirmationModalPlugin = {
  install(Vue, { vuetify }) {
    const instance = createConfirmationModalInstance(vuetify);
    Vue.prototype.$confirmation = (title, message, action, close) => {
      instance.showModal(title, message, action, close);
    };
  }
};

export default ConfirmationModalPlugin;
