<template>
  <v-container fluid>
    <h1>Developments View</h1>
    <v-divider></v-divider>
    <v-text-field
      v-model="search"
      clearable
      flat
      hide-details
      prepend-inner-icon="mdi-magnify"
      label="Search"
      class="mt-2"
    ></v-text-field>
    <GridDevelopments
      :developments="developmentsList"
      :search="search"
      :loading="loading"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RootLayout from "../../components/layouts/RootLayout.vue";
import GridDevelopments from "../../components/root/GridDevelopments.vue";
export default {
  name: "ListDevelopments",
  components: {
    GridDevelopments
  },
  async created() {
    this.$emit(`update:layout`, RootLayout);
    this.loading = true;
    await this.fetchDevelopments();
    this.loading = false;
  },
  mounted() {},
  data() {
    return {
      search: "",
      loading: false
    };
  },
  computed: {
    ...mapState("root", {
      developmentsList: state => state.developmentsList
    })
  },
  methods: {
    ...mapActions("root", {
      fetchDevelopments: "fetchDevelopmentsList"
    })
  }
};
</script>

<style></style>
