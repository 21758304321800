import request from "../helpers/request";

// Obtiene las propiedades y algunos datos del desarrollo: por ejemplo las reglas de precio..
export function GetPropertiesAndDevelopment(realEstateDevelopmentCode) {
  try {
    let data = {};
    if (realEstateDevelopmentCode) {
      data.real_estate_development_code = realEstateDevelopmentCode;
    }

    return new Promise((resolve, reject) => {
      request("/property/list", data)
        .then(response => {
          resolve({
            data: response.data
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// obtiene la lista de propiedades
export async function GetProperties(realEstateDevelopmentCode) {
  try {
    let data = {};
    if (realEstateDevelopmentCode) {
      data.real_estate_development_code = realEstateDevelopmentCode;
    }

    return new Promise((resolve, reject) => {
      request("/property/list", data)
        .then(response => {
          resolve({
            Property: response.data.Property
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// obtiene la información de una propiedad
export function GetPropertyDetail(propertyId) {
  try {
    if (!propertyId) return Promise.reject("No se especificó propertyId");

    let data = {
      propertyId: propertyId
    };

    return new Promise((resolve, reject) => {
      request("/property/detail", data)
        .then(response => {
          resolve({
            Property: response.data.Property
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// registra una nueva propiedad
export function CreateProperty(property) {
  try {
    let data = {};

    data = {
      code: property.code,
      contract_status: property.contract_status,
      contact: property.contact,
      building: property.building,
      construction_allowed: property.construction_allowed,
      media: property.media,
      miscellaneous: property.miscellaneous,
      pricing: property.pricing,
      floor: property.floor,
      tower: property.tower,
      features: property.features,
      real_estate_development: property.real_estate_development,
      amenities: property.amenities,
      type: property.type,
      status: 1
    };

    return new Promise((resolve, reject) => {
      request("/property/create", data)
        .then(response => {
          resolve({
            Property: response.data.Property
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// actualiza información de una propiedad
export function UpdateProperty(propertyId, dataProperty) {
  try {
    if (!propertyId) return Promise.reject("No se especificó propertyId");

    let data = {
      propertyID: propertyId,
      property: {}
    };
    if (dataProperty.code && dataProperty.code.length > 0) {
      data.property.code = dataProperty.code;
    }
    if (dataProperty.contract_status) {
      data.property.contract_status = dataProperty.contract_status;
    }
    if (dataProperty.pricing) {
      data.property.pricing = {};
      if (dataProperty.pricing.price)
        data.property.pricing.price = dataProperty.pricing.price;
      if (dataProperty.pricing.currency)
        data.property.pricing.currency = dataProperty.pricing.currency;
    }

    if (dataProperty.features) {
      data.property.features = {};
      if (dataProperty.features.rooms)
        data.property.features.rooms = dataProperty.features.rooms;
      if (dataProperty.features.bathrooms)
        data.property.features.bathrooms = dataProperty.features.bathrooms;
    }

    if (dataProperty.building) {
      data.property.building = {};
      if (dataProperty.building.type)
        data.property.building.type = dataProperty.building.type;
      if (dataProperty.building.construction)
        data.property.building.construction =
          dataProperty.building.construction;
      if (dataProperty.building.terrace)
        data.property.building.terrace = dataProperty.building.terrace;
      if (dataProperty.building.total)
        data.property.building.total = dataProperty.building.total;
    }

    if (dataProperty.floor) {
      data.property.floor = {};
      if (dataProperty.floor.name)
        data.property.floor.name = dataProperty.floor.name;
    }

    if (dataProperty.tower) {
      data.property.tower = {};
      if (dataProperty.tower.code)
        data.property.tower.code = dataProperty.tower.code;
      if (dataProperty.tower.name)
        data.property.tower.name = dataProperty.tower.name;
    }

    if (dataProperty.miscellaneous) {
      data.property.miscellaneous = {};
      if (dataProperty.miscellaneous.mapping_coords)
        data.property.miscellaneous.mapping_coords =
          dataProperty.miscellaneous.mapping_coords;
      if (dataProperty.miscellaneous.mapping_coords_building)
        data.property.miscellaneous.mapping_coords_building =
          dataProperty.miscellaneous.mapping_coords_building;
    }
    return new Promise((resolve, reject) => {
      request("/property/update", data)
        .then(response => {
          resolve({
            Property: response.data.Property
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetPropertiesStatus(realEstateDevelopmentCode) {
  try {
    let data = {
      real_estate_development_code: realEstateDevelopmentCode
    };

    return new Promise((resolve, reject) => {
      request("/Stats/PropertiesContractStatus", data)
        .then(response => {
          resolve({
            Departamentos: response.Departamentos
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
