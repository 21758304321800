import {
  getExternalBroker,
  editExternalBroker,
  createBrokerExternal
} from "@/api/external-broker-api.js";
import Vue from "vue";

// const mockBrex = {
//   _id: "64ac47fc1b075f0035e3a7ca",
//   real_estate_develop_id: [],
//   player_id: "0",
//   exam_passed: [],
//   status: 1,
//   phone: "+529952124578",
//   type: "external",
//   created_at: "2023-07-10T18:03:40.097Z",
//   updated_at: "2023-07-10T18:03:40.097Z",
//   real_estate_group_id: "5e9a2df024535649bdc51f54",
//   name: "Tulum form test",
//   email: "tulum@gardensc28.com",
//   raw_opportunities_array: [
//     {
//       raw_opportunity_id: "64ac47fc1b075f0035e3a7c7",
//       observations:
//         "Mensaje: Prueba del formulario de Tulum Gardens.\tEnviado desde el desarrollo de Tulum Gardens. Del sitio: costarealtygroupmexico.com",
//       source_id: "6165b37aedfca25de9ea9dd3",
//       source_name: "Página Web",
//       budget: 0,
//       registered: false
//     }
//   ]
// };
// const moocBrexList = [mockBrex, mockBrex];

export default {
  namespaced: true,
  state: {
    brexList: [] // lista de brokers externos
  },
  getters: {
    // Cuenta las opportunidades registradas y no registradas
    countOpportunitiesGlobal(state) {
      let count = { register: 0, registered: 0 };
      state.brexList.forEach(brex => {
        const rawOpportunitiesArray = brex.raw_opportunities_array || [];
        count.register += rawOpportunitiesArray.filter(
          opp => !opp.registered
        ).length;
        count.registered += rawOpportunitiesArray.filter(
          opp => opp.registered
        ).length;
      });
      return count;
    }
  },
  mutations: {
    setBrexList(state, data) {
      state.brexList = data;
    },
    setBrex(state, data) {
      const index = state.brexList.findIndex(brex => brex._id == data._id);
      Vue.set(state.brexList, index, data);
    },
    addBrex(state, data) {
      state.brexList.push(data);
    }
  },
  actions: {
    async fetchBrexList({ commit }) {
      const brexs = await getExternalBroker();
      commit("setBrexList", brexs);
    },
    async updateBrex({ commit }, brex) {
      const edited = await editExternalBroker(brex);
      commit("setBrex", edited.data);
      return true;
    },
    async createBrex({ commit }, data) {
      try {
        const newBrex = await createBrokerExternal(data);
        commit("addBrex", newBrex);
        return true;
      } catch (error) {
        return false;
      }
    },
    async createBrexExternalPresentation({ commit }, data) {
      try {
        const newBrex = await createBrokerExternal(data);
        commit("addBrex", newBrex);
        return newBrex;
      } catch (error) {
        return false;
      }
    }
  }
};
