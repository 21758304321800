<template>
  <div>
    <v-app-bar dense elevation="0" app>
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-app-bar-title>Hola, Root :)</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>

      <v-btn icon>
        <v-badge dot color="error" overlap>
          <v-icon color="primary">
            mdi-bell
          </v-icon>
        </v-badge>
      </v-btn>

      <v-btn icon>
        <v-avatar size="36px">
          <img
            alt="Avatar"
            src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
          />
        </v-avatar>
      </v-btn>
      <!-- icon logout -->
      <v-btn icon @click="logOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :permanent="!isMobile"
      :mini-variant.sync="isMobile ? false : mini"
      app
    >
      <v-list-item>
        <v-list-item-icon>
          <v-app-bar-nav-icon
            v-if="!isMobile"
            @click.stop="mini = !mini"
          ></v-app-bar-nav-icon>
          <v-app-bar-nav-icon
            v-else
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Capital 28
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="{ name: item.view }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { LogOut } from "@/api/user-api.js";

export default {
  name: "RootLayout",
  data() {
    return {
      drawer: true,
      mini: false,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          view: "Rootdashboard"
        },
        {
          title: "Inmobiliarias",
          icon: "mdi-office-building",
          view: "Rootinmos"
        },
        { title: "Desarrollos", icon: "mdi-home", view: "Rootdevelopments" },
        { title: "Usuarios", icon: "mdi-account-group", view: "Rootusers" }
      ],
      right: null
    };
  },
  created() {},
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    }
  },
  methods: {
    logOut() {
      LogOut().then(() => {
        location.reload();
      });
    }
  }
};
</script>

<style></style>
