<template>
  <v-dialog v-model="value" max-width="500">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="white--text" @click="closeConfirm">
          cerrar
        </v-btn>
        <v-btn color="red darken-4 " class="white--text" @click="confirm">
          aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Confirmation",
  data() {
    return {
      value: false,
      title: "",
      message: "",
      action: async () => {},
      close: null
    };
  },
  methods: {
    async confirm() {
      this.value = false;
      return await this.action();
    },
    closeConfirm() {
      this.value = false;
      if (this.close) this.close();
    },
    showModal(title, message, action, close) {
      this.title = title;
      this.message = message;
      this.action = action;
      this.close = close;
      this.value = true;
    }
  }
};
</script>
