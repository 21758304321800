<template>
  <v-dialog v-model="dialog" max-width="600px" height="">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
        Nueva Inmobiliaria
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Nuevo Inmobiliaria</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formCreateInmo" v-model="valid">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="name"
                label="Nombre*"
                required
                :rules="nameRules"
              ></v-text-field>
              NOTA: Las <strong>fuentes y medios </strong>se asignaran por
              <strong>defecto</strong>, si desea cambiarlo vaya a la sección de
              edición.
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">
          Close
        </v-btn>
        <v-btn color="primary" text @click="saveUser()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ModalCreateInmo",
  data() {
    return {
      dialog: false,
      valid: true,
      name: "",
      nameRules: [v => !!v || "El nombre es requerido"]
    };
  },
  mounted() {},
  methods: {
    ...mapActions("root", {
      createInmo: "createInmo"
    }),
    closeDialog() {
      this.dialog = false;
    },
    async saveUser() {
      if (!this.$refs.formCreateInmo.validate()) {
        return;
      }
      const res = await this.createInmo({ name: this.name });
      if (res.data) {
        this.$snotify.success("Inmobiliaria creada correctamente");
        this.closeDialog();
      } else {
        this.$snotify.error(`${res}`);
      }
    }
  }
};
</script>
