import { requestV2 } from "../helpers/request";

const baseEndpoint = "/maintenance";
const pathInmos = "/realEstateGroups";
const pathDevelopments = "/developments";
const pathUsers = "/users";
const pathLeads = "/lead";
const pathSourceAndMediums = "/sourceAndMediums";
// * Request Inmos
async function fetchInmos(filter = {}) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}`,
    bodyRequest: filter,
    defaultResponse: []
  });
}
async function fetchInmoDetail(id) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${id}`,
    defaultResponse: {}
  });
}
async function createInmo(inmo) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/create`,
    bodyRequest: inmo,
    defaultResponse: {}
  });
}
async function updateInmo(id, inmo) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${id}/update`,
    bodyRequest: inmo,
    defaultResponse: {}
  });
}
async function generateInmoToken(data) {
  return requestV2({
    endpoint: `${baseEndpoint}/generateToken`,
    bodyRequest: data,
    defaultResponse: {}
  });
}
async function fetchTraffic(inmoId) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${inmoId}/traffics`,
    defaultResponse: {}
  });
}
async function deleteTraffic({ inmoId, trafficId }) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${inmoId}/traffics/${trafficId}/delete`,
    bodyRequest: {},
    defaultResponse: {}
  });
}
async function createTraffic(inmoId, body) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${inmoId}/traffics/add`,
    bodyRequest: body,
    defaultResponse: {}
  });
}
//* Request Developments
async function fetchDevelopments(filter = {}) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathDevelopments}`,
    bodyRequest: filter,
    defaultResponse: []
  });
}
async function fetchDevelopmentsByInmoId(inmoId) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathDevelopments}?group=${inmoId}`,
    defaultResponse: []
  });
}
//* Request Users
async function fetchUsers(filter = {}) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathUsers}`,
    bodyRequest: filter,
    defaultResponse: []
  });
}
async function fetchUsersByInmoId(inmoId) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathUsers}?group=${inmoId}`,
    defaultResponse: []
  });
}
async function createUser(user) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathUsers}/create`,
    bodyRequest: user,
    defaultResponse: {}
  });
}
async function changeRoleUser({ userId, role }) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathUsers}/${userId}/changeRole`,
    bodyRequest: { role },
    defaultResponse: null
  });
}
//* Request Carrusel
async function fetchCarrusel(inmoId) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${inmoId}/carrousels`,
    defaultResponse: []
  });
}
async function createCarrusel(inmoId, carrusel) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${inmoId}/carrousels/create`,
    bodyRequest: carrusel,
    defaultResponse: {}
  });
}
async function searchCampaign(id, body) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${id}/carrousels/search`,
    bodyRequest: body,
    defaultResponse: {}
  });
}
async function deleteCarousel(id, inmoId) {
  return requestV2({
    endpoint: `${baseEndpoint}${pathInmos}/${id}/carrousels/delete/${inmoId}`,
    bodyRequest: {},
    defaultResponse: {}
  });
}
// * Request Leads
async function fetchLeads(inmoId, pagination = {}, filters = {}, search = "") {
  const bodyRequest = {
    ...pagination,
    filters,
    search
  };
  return requestV2({
    endpoint: `${baseEndpoint}/${inmoId}${pathLeads}/list`,
    bodyRequest,
    defaultResponse: {}
  });
}
async function deleteLead(leadId) {
  return requestV2({
    endpoint: `${baseEndpoint}/deleteLead`,
    bodyRequest: {
      _id: leadId
    },
    defaultResponse: {}
  });
}
// * Request Source and Mediums
async function fetchSourceAndMediums() {
  return requestV2({
    endpoint: `${baseEndpoint}${pathSourceAndMediums}`,
    bodyRequest: {},
    defaultResponse: {}
  });
}
// * General
async function getUserSys() {
  return requestV2({
    endpoint: `${baseEndpoint}/systemUserStatus`,
    bodyRequest: {},
    defaultResponse: {}
  });
}

async function changeInmoMoveSys(userId, groupId) {
  return requestV2({
    endpoint: `${baseEndpoint}/moveSys`,
    bodyRequest: {
      userId,
      groupId
    }
  });
}
export {
  fetchInmos,
  fetchInmoDetail,
  fetchDevelopments,
  fetchDevelopmentsByInmoId,
  fetchUsers,
  fetchUsersByInmoId,
  createUser,
  createInmo,
  fetchCarrusel,
  createCarrusel,
  updateInmo,
  searchCampaign,
  deleteCarousel,
  generateInmoToken,
  fetchLeads,
  fetchTraffic,
  fetchSourceAndMediums,
  deleteTraffic,
  createTraffic,
  deleteLead,
  getUserSys,
  changeInmoMoveSys,
  changeRoleUser
};
