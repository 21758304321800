<template>
  <v-container>
    <h1>
      Carruseles
    </h1>
    <v-data-table
      :headers="headers"
      :items="carousels"
      :search="search"
      :loading="loading"
      sort-by="date_assigned"
      :sort-desc="true"
      :loading-text="'Cargando carruseles...'"
      pagination.sync="pagination"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <CreateCarrouselVue />
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hint="Buscar por nombre, camapaña"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:header.date_assigned="{ header }">
        <div class="d-flex flex-row align-center">
          {{ header.text }}
          <HeaderLastAsigned />
        </div>
      </template>
      <template v-slot:item="{ item }">
        <ItemCarrusel :carrousel="item" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateCarrouselVue from "./forms/CreateCarrousel.vue";
import ItemCarrusel from "./ItemCarrusel.vue";
import HeaderLastAsigned from "./components/carrousel/HeaderLastAsigned.vue";

export default {
  name: "CarouselsByInmo",
  components: {
    ItemCarrusel,
    CreateCarrouselVue,
    HeaderLastAsigned
  },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "name"
        },
        { text: "Contactos", value: "contact_list" },
        { text: "Creado", value: "created_at" },
        { text: "Ultima asignación", value: "date_assigned" },
        {
          text: "Estado",
          value: "status",
          align: "start",
          sortable: false
        },
        {
          text: "Tipo de asignación",
          value: "name_aliases",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    await this.getCarousels(id);
  },
  computed: {
    ...mapState("root", {
      carousels: state => state.carouselList
    })
  },
  methods: {
    ...mapActions("root", {
      fetchCarousels: "fetchCarrusels"
    }),
    async getCarousels(id) {
      this.loading = true;
      await this.fetchCarousels(id);
      this.loading = false;
    }
  }
};
</script>

<style>
/* .container__Carrousel {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem;
} */
.container__Carrousel {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tres columnas de igual tamaño */
  gap: 0.5rem; /* Espacio entre las celdas del grid */
  margin: 1rem;
}
</style>
