<template>
  <v-card max-width="344">
    <v-toolbar :color="background" flat height="180">
      <v-img
        :src="getImg().src"
        :alt="getImg().alt_text"
        contain
        height="180px"
      ></v-img>
    </v-toolbar>

    <v-card-title>
      {{ development?.name.toUpperCase() || "Sin Nombre" }}
    </v-card-title>

    <v-card-subtitle>
      <span>
        <strong>Inmobiliaria:</strong>
        {{
          getInmoById(development.real_estate_group_id)?.name ||
            "Sin Inmobiliaria"
        }}</span
      >
      <p>
        <strong>Zones:</strong> {{ development.name }}
        <custom-tool-tip
          align="top"
          text="Copiar al portapapeles"
          icon="mdi-content-copy"
          size="small"
          :action="() => copyText(development.name)"
        />
      </p>
    </v-card-subtitle>
    <v-card-text>
      <strong>GroupId:</strong>
      {{ development._id }}
      <custom-tool-tip
        align="top"
        text="Copiar al portapapeles"
        icon="mdi-content-copy"
        size="small"
        :action="() => copyText(development._id)"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn color="orange lighten-2" text>
        Explore
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CustomToolTip from "./common/CustomToolTip.vue";
export default {
  name: "CardDevelopment",
  components: {
    CustomToolTip
  },
  props: {
    development: {
      type: Object,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      background: "blue-grey lighten-4"
    };
  },
  computed: {
    ...mapGetters("root", {
      getInmoById: "getInmoById"
    })
  },
  methods: {
    getImg() {
      if (this.development.media?.featured_image?.src) {
        return {
          src: this.development.media.featured_image.src,
          alt_text: this.development.media.featured_image.alt_text
        };
      }
      return {
        src: `${process.env.BASE_URL}img/undraw_house_searching_n8mp.svg`,
        alt_text: "Capital28"
      };
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$snotify.success("Copiado al portapapeles");
      } catch (error) {
        this.$snotify.error("Error al copiar al portapapeles");
      }
    }
  }
};
</script>
