function getTypeAsignation(carrousel) {
  if (carrousel?.name_aliases?.length) return "Multi-Campaña";
  if (carrousel?.name === "General") return "General";
  if (carrousel?.real_estate_develop_id) return "Desarrollo";
  if (carrousel?.marketing_assigned) return "Campaña";
  return "N/A";
}

function getStatus(carrousel) {
  if (carrousel?.status === 0) return { color: "red", text: "Inactivo" };
  if (
    !carrousel.contact_list.some(contact => {
      return contact.status === 1;
    })
  )
    return { color: "orange", text: "Error" };
  if (carrousel?.status === 1) return { color: "green", text: "Activo" };
}

export { getTypeAsignation, getStatus };
