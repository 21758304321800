<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Nombre"
          :readonly="!edit"
          persistent-hint
          v-model="logo.name"
          @input="changeDataEdit('name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Texto Alternativo"
          :readonly="!edit"
          v-model="logo.alt_text"
          @input="changeDataEdit('alt_text', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          label="Link"
          :readonly="!edit"
          v-model="logo.src"
          @input="changeDataEdit('src', $event)"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="12">
        <v-text-field
          label="CloudinaryId"
          :readonly="!edit"
          v-model="logo.cloudinary_id"
          @input="changeDataEdit('cloudinary_id', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <v-btn v-if="!edit" color="primary" @click="edit = true">Editar</v-btn>
      <v-btn v-if="edit" @click="updateMedia" color="success" class="mx-2"
        >Actualizar</v-btn
      >
      <v-btn v-if="edit" @click="cancelEdit" color="error" class="mx-2"
        >Cancelar</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MediaForm",
  props: {
    mediaInmo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataEdit: { logo: {} },
      logo: { ...this.getMedia().logo },
      edit: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions("root", ["updateInmo"]),
    getMedia() {
      return this.mediaInmo;
    },
    changeDataEdit(key, value) {
      this.dataEdit.logo[key] = value;
    },
    async updateMedia() {
      if (Object.keys(this.dataEdit.logo).length === 0) return;
      const id = this.$route.params.id;
      const inmo = { media: this.dataEdit };
      const res = await this.updateInmo({ id, inmo });
      if (res) {
        this.edit = false;
        this.dataEdit = { logo: {} };
      }
      if (res.data)
        return this.$snotify.success("Inmobiliaria actualizada", "Completado");
      this.$snotify.error("No se pudo actualizar inmobiliaria", "Error");
    },
    cancelEdit() {
      this.edit = false;
      this.dataEdit = { logo: {} };
      this.logo = { ...this.getMedia().logo };
    }
  }
};
</script>

<style></style>
