import { GetLogs } from "@/api/log-api.js";

export default {
  namespaced: true,
  state: {
    items: [], // lista de todos los logs
    loading: false
  },
  getters: {},
  mutations: {
    setLogs(state, logs) {
      // actualiza la información de los leads en Vuex
      state.items = logs;
    },
    setLoading(state, loading) {
      // establece que se esta cargando información
      state.loading = loading;
    }
  },
  actions: {
    fetchLogs({ commit }) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          GetLogs()
            .then(res => {
              commit("setLogs", res.Log);
              resolve();
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
};
