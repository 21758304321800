<template>
  <div>
    <v-main v-if="!v2" class="login primary">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" xl="3">
            <v-card class="elevation-12">
              <v-form ref="form" @submit.prevent="handleSubmit">
                <v-toolbar
                  color="secondary"
                  class="white--text"
                  flat
                  height="120"
                >
                  <v-spacer />
                  <v-img
                    contain
                    :src="logo"
                    min-height="50"
                    max-width="60%"
                    height="auto"
                    width="100%"
                  />
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    label="Usuario"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="email"
                    :rules="emailRules"
                  />
                  <v-text-field
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="() => (showPassword = !showPassword)"
                    v-model="password"
                    :rules="passwordRules"
                  />
                  <v-alert
                    v-show="messageError"
                    outlined
                    type="error"
                    border="left"
                    text
                  >
                    {{ messageError }}
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    type="submit"
                    color="primary"
                    :disabled="loading"
                    :loading="loading"
                  >
                    Login
                    <template v-slot:loader>
                      <v-img
                        class="primary rounded elevation-2"
                        aspect-ratio="2"
                        contain
                        height="36px"
                        :src="`${publicPath}c28-animation-2.gif`"
                      >
                      </v-img>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <span class="last-modified-log">23 julio 2020 16:03pm</span>
      </v-container>
    </v-main>
    <login-version-2 v-else />
  </div>
</template>

<script>
import { LogIn, IsLogged } from "@/api/user-api.js";
import BlankLayout from "@/components/layouts/BlankLayout.vue";
import LoginVersion2 from "@/views/LoginV2.vue";

export default {
  components: {
    LoginVersion2
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      v2: false, // nuevo diseño
      email: "",
      password: "",
      showPassword: false,
      messageError: "",
      showLoginForm: false,
      logo: require("@/assets/logo-capital28-blanco.png"),
      loading: false,
      submitted: false,
      emailRules: [
        v => !!v || "Ingrese su correo",
        v => /.+@.+/.test(v) || "Correo inválido"
      ],
      passwordRules: [v => !!v || "Ingrese su contraseña"]
    };
  },
  created() {
    this.$emit(`update:layout`, BlankLayout);
    // checar si el usuario esta logueado
    this.isLoggedUser();
  },
  methods: {
    // listeners
    handleSubmit() {
      this.submitted = true;
      if (!this.loading) {
        if (this.$refs.form.validate()) {
          this.login();
        }
      }
    },
    async login() {
      let self = this;
      self.loading = true;
      self.messageError = "";

      await LogIn(self.email, self.password)
        .then(res => {
          if (res.Role) self.redirectDasboard(res.Role);
        })
        .catch(err => {
          self.messageError = err;
        })
        .finally(() => {
          self.loading = false;
        });
    },
    redirectDasboard(role) {
      this.$router.push({ path: `${role}/` });
    },
    isLoggedUser() {
      try {
        let self = this;
        let role = IsLogged().Role;
        if (role) self.redirectDasboard(role);
      } catch (err) {
        // report err
      }
    }
  }
};
</script>
<style>
.login {
  height: 100vh;
}
.last-modified-log {
  position: absolute;
  visibility: hidden;
  bottom: 0;
  right: 0;
}
</style>
