import request from "../helpers/request";

export function SendNotification(dataNotification) {
  try {
    let data = {};
    if (dataNotification.message) data.message = dataNotification.message;
    if (dataNotification.contact_id)
      data.contact_id = dataNotification.contact_id;
    if (dataNotification.role) data.role = dataNotification.role;
    if (dataNotification.lead_id) data.lead_id = dataNotification.lead_id;
    if (dataNotification.notificationType)
      data.notificationType = dataNotification.notificationType;

    return new Promise((resolve, reject) => {
      request("/notification/sendNotification", data)
        .then(response => {
          resolve({
            Notifiers: response.data.contact,
            Headings: response.data.headings
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export function SendNotificationNewComment(leadId, commentMessage) {
  try {
    let data = {
      leadId,
      message: commentMessage
    };

    return new Promise((resolve, reject) => {
      request("/notification/newComment", data)
        .then(response => {
          resolve({
            NotifiedUsers: response.data.NotifiedUsers,
            ResponseNotification: response.data.ResponseNotification
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}
export function SendNotificationComment(body) {
  try {
    return new Promise((resolve, reject) => {
      request("/notification/sendNotificationComment", body)
        .then(response => {
          resolve({
            NotifiedUsers: response.data.NotifiedUsers,
            ResponseNotification: response.data.ResponseNotification
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export function SendNotificationAssignBroker(leadId, dataNotification) {
  try {
    let data = {};
    if (leadId) data.lead_id = leadId;
    if (dataNotification.zones) {
      data.zones = dataNotification.zones;
      data.comment = `Interesado en: ${dataNotification.zones}`;
    }
    if (dataNotification.contactBrokerId) {
      data.internal_broker_id = dataNotification.contactBrokerId;
      data.headings = `Nuevo Lead | ${dataNotification.nameAssignBroker} asigned`;
    }
    data.sendNotificationToDeveloper = 1;
    data.notificationType = "assigned-lead";
    return new Promise((resolve, reject) => {
      request("/notification/sendNotificationAssignBroker", data)
        .then(response => {
          resolve({
            Notifiers: {},
            Headings: { response }
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export function SendNotificationReassigned(dataNotification) {
  try {
    let data = {};
    if (dataNotification.contact_id)
      data.contact_id = dataNotification.contact_id;
    if (dataNotification.role) data.role = dataNotification.role;
    if (dataNotification.nLeads) data.nLeads = dataNotification.nLeads;

    return new Promise((resolve, reject) => {
      request("/notification/sendNotificationReassigned", data)
        .then(response => {
          resolve({
            Data: response.Data,
            Message: response.Message
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}
