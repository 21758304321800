<template>
  <v-img :src="getSrc" @error="error = true" />
</template>
<script>
export default {
  name: "CustomDefaultImage",
  props: {
    src: {
      type: Object,
      required: true
    },
    default: {
      type: String,
      default: "https://via.placeholder.com/150"
    }
  },
  data() {
    return {
      error: false
    };
  },
  computed: {
    getSrc() {
      return this.error ? this.default : this.src;
    }
  }
};
</script>
