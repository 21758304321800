<template>
  <tr>
    <td>
      <img :src="getLogo()" height="30" />
    </td>
    <td>{{ inmo.name }}</td>
    <td>
      <v-chip small :color="status.color" dark>{{ status.text }}</v-chip>
    </td>
    <td :class="inmo.leadsInPeriod > 0 ? '' : 'red--text font-weight-bold'">
      {{ inmo.leadsInPeriod }}
    </td>
    <td :class="inmo.commentsInPeriod > 0 ? '' : 'red--text font-weight-bold'">
      {{ inmo.commentsInPeriod }}
    </td>

    <td>
      <v-btn icon @click="viewDetails()">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  name: "CardRealEstateGroup",
  props: {
    inmo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      background: "blue-grey lighten-4"
    };
  },
  computed: {
    status() {
      const active = { color: "green", text: "Activo" };
      const inactive = { color: "red", text: "Desactivado" };
      if (this.inmo.active === undefined || this.inmo.active === null)
        return active;
      return this.inmo.active ? active : inactive;
    }
  },
  methods: {
    getLogo() {
      if (this.inmo.media?.logo?.src) {
        return this.inmo.media.logo.src;
      }
      //this.background = "primary";
      return require("@/assets/logoC28_primary.png");
    },
    viewDetails() {
      this.$router.push({
        name: "RootdetailInmo",
        params: { id: this.inmo._id }
      });
    }
  }
};
</script>

<style scoped>
td {
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}
tr:hover {
  background-color: #f2f2f2;
}
tr img {
  max-width: 60%;
}
</style>
