<template>
  <v-container fluid>
    <h1>Inmos View</h1>
    <v-divider></v-divider>
    <div class="header__container">
      <ModalCreateInmoVue />
      <DateRangeComponent
        v-model="date"
        default="Ayer"
        label="Periodo de ultimos leads"
        ref="dateRange"
        :options="['Hoy', 'Ayer', 'Últimos 7 días', 'Últimos 30 días']"
        :dense="true"
      />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        clearable
        flat
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Search"
        class="mt-2"
      ></v-text-field>
    </div>
    <v-data-table
      :headers="headers"
      :items="inmosList"
      :search="search"
      :loading="loading"
      sort-by="leadsInPeriod"
      :sort-desc="true"
    >
      <template v-slot:body="props">
        <CardRealEstateGroup
          v-for="inmo in props.items"
          :key="inmo._id"
          :inmo="inmo"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RootLayout from "../../components/layouts/RootLayout.vue";
import CardRealEstateGroup from "../../components/root/CardRealEstateGroup.vue";
import ModalCreateInmoVue from "../../components/root/forms/ModalCreateInmo.vue";
import DateRangeComponent from "../../components/reports-kpis/component/dateRange.component.vue";
export default {
  name: "ListInmos",
  components: {
    CardRealEstateGroup,
    ModalCreateInmoVue,
    DateRangeComponent
  },
  created() {
    this.$emit(`update:layout`, RootLayout);
  },
  data() {
    return {
      search: "",
      date: {
        start: null,
        end: null
      },
      loading: false,
      headers: [
        {
          text: "Logo",
          value: "media.logo.src",
          sortable: false
        },
        {
          text: "Nombre",
          value: "name"
        },
        {
          text: "Estado",
          value: "active"
        },
        {
          text: "Ultimos leads",
          value: "leadsInPeriod"
        },
        {
          text: "Ultimos comentarios",
          value: "commentsInPeriod"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState("root", {
      inmosList: state => state.inmosList
    })
  },
  methods: {
    ...mapActions("root", {
      fetchInmos: "fetchInmosList"
    }),
    async fetchInmosList({ gte = null } = {}) {
      this.loading = true;
      await this.fetchInmos({ gte });
      this.loading = false;
    }
  },
  watch: {
    date: {
      handler() {
        this.fetchInmosList({ gte: this.date.start });
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.container__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 1rem;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 10px;
}
</style>
