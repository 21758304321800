//? Nuevas rutas:
import DeveloperMiddleware from "../middlewares/developer-middleware";
const slug = "/developer";
const role = "Developer";

const routes = [
  {
    path: slug,
    redirect: `${slug}/leads`,
    name: role
  },
  /* Overview */
  {
    path: `${slug}/leads`,
    name: `${role}LeadsView`,
    component: () => import("../views/lead/DeveloperLeads"),
    meta: {
      middleware: [DeveloperMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/list`,
    name: `${role}LeadListView`,
    component: () => import("../views/lead/DeveloperLeadView"),
    redirect: `${slug}/lead/list`,
    meta: {
      middleware: [DeveloperMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/kanban`,
    name: `${role}LeadKanbanView`,
    redirect: `${slug}/lead/list`,
    component: () => import("../views/lead/DeveloperLeadKanbanView"),
    meta: {
      middleware: [DeveloperMiddleware],
      title: "Leads"
    }
  },

  {
    path: `${slug}/reports`,
    name: `${role}CampaignView`,
    component: () => import("../views/reports-campaings/TableReportKpis.vue"),
    meta: {
      middleware: [DeveloperMiddleware],
      title: "Reportes"
    }
  },
  /* Propiedades */
  {
    path: `${slug}/property/list`,
    name: `${role}PropertyView`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [DeveloperMiddleware],
      title: "Propiedades"
    }
  }
];

export default routes;
