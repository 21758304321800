import request from "../helpers/request";

// obtiene la lista de leads
export function GetLeadsFilters() {
  try {
    return new Promise((resolve, reject) => {
      request("/Contacts/Filters", {})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetLeadsDuplicates(field, page) {
  try {
    return new Promise((resolve, reject) => {
      request("/lead/getLeadRealEstate", {
        duplicate: true,
        duplicateSearch: field ? field : "",
        paginationActive: true,
        limit: 25,
        page: page
      })
        .then(response => {
          let leads = response.data.Lead.map(el => {
            var o = Object.assign({}, el);
            if (
              "internal_admin" in o &&
              Object.entries(o.internal_admin).length === 0
            ) {
              o.internal_broker = o.internal_admin;
            }
            if (!("observations_detail" in o)) {
              o.observations_detail = {
                message: o.observations ? o.observations : "",
                link: "",
                category: "",
                interested_unit: "",
                contact_link: ""
              };
            }
            return o;
          });
          resolve({
            Lead: leads,
            Options: response.data.tableFooter
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetLeadsSearched(search) {
  try {
    return new Promise((resolve, reject) => {
      request("/lead/getLead", {
        sortedBy: "recents",
        searchActive: true,
        search: search
      })
        .then(response => {
          let leads = response.data.Lead.map(el => {
            var o = Object.assign({}, el);
            if (
              "internal_admin" in o &&
              Object.entries(o.internal_admin).length === 0
            ) {
              o.internal_broker = o.internal_admin;
            }
            if (!("observations_detail" in o)) {
              o.observations_detail = {
                message: o.observations ? o.observations : "",
                link: "",
                category: "",
                interested_unit: "",
                contact_link: ""
              };
            }
            return o;
          });

          resolve({
            Lead: leads
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetLeadsWithFilters(filter) {
  try {
    return new Promise(resolve => {
      let body =
        filter !== {}
          ? {
              sortedBy: "recents",
              filter: filter
            }
          : {
              sortedBy: "recents"
            };

      request("/lead/getLead", body)
        .then(response => {
          let leads = response.data.Lead.map(el => {
            var o = Object.assign({}, el);
            if (
              "internal_admin" in o &&
              Object.entries(o.internal_admin).length === 0
            ) {
              o.internal_broker = o.internal_admin;
            }
            if (!("observations_detail" in o)) {
              o.observations_detail = {
                message: o.observations ? o.observations : "",
                link: "",
                category: "",
                interested_unit: "",
                contact_link: ""
              };
            }
            if (!o.contact_lead) {
              o.contact_lead = {
                name: "Sin Nombre",
                email: "",
                phone: "",
                location: ""
              };
            }
            return o;
          });

          resolve({
            Options:
              response.data && response.data.tableFooter
                ? response.data.tableFooter
                : {},
            Lead: leads
          });
        })
        .catch(err => {
          resolve({
            Options: {},
            Lead: [],
            Message: err
          });
        });
    });
  } catch (err) {
    return Promise.resolve({
      Options: {},
      Lead: [],
      Message: err
    });
  }
}
export function GetLeadsPagination(page, limit, filter, sortBy) {
  try {
    return new Promise(resolve => {
      let body =
        filter !== {}
          ? {
              sortedBy: sortBy,
              searchActive: false,
              paginationActive: true,
              limit: limit,
              page: page,
              filter: filter
            }
          : {
              sortedBy: sortBy,
              searchActive: false,
              paginationActive: true,
              limit: limit,
              page: page
            };

      request("/lead/getLead", body)
        .then(response => {
          let leads = response.data.Lead.map(el => {
            var o = Object.assign({}, el);
            if (
              "internal_admin" in o &&
              Object.entries(o.internal_admin).length === 0
            ) {
              o.internal_broker = o.internal_admin;
            }
            if (!("observations_detail" in o)) {
              o.observations_detail = {
                message: o.observations ? o.observations : "",
                link: "",
                category: "",
                interested_unit: "",
                contact_link: ""
              };
            }
            if (!o.contact_lead) {
              o.contact_lead = {
                name: "Sin Nombre",
                email: "",
                phone: "",
                location: ""
              };
            }
            return o;
          });

          resolve({
            Options:
              response.data && response.data.tableFooter
                ? response.data.tableFooter
                : {},
            Lead: leads
          });
        })
        .catch(err => {
          resolve({
            Options: {},
            Lead: [],
            Message: err
          });
        });
    });
  } catch (err) {
    return Promise.resolve({
      Options: {},
      Lead: [],
      Message: err
    });
  }
}

export function GetLeadByGroup() {
  // Obtener los lead pelon pelooon
  try {
    return new Promise((resolve, reject) => {
      request("/lead/getLeadByGroup", {})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function GetLeadsById(item) {
  try {
    let data = {};
    // mandar el id del contacto en caso de que se mande
    // si es admin, este no es necesario
    if (item && item._id) {
      data.filter = {
        type: "phase",
        get: ["finished", "discarded", "active", "in-operation"],
        contactId: item._id
      };
    }

    return new Promise((resolve, reject) => {
      request("/lead/getLead", data)
        .then(response => {
          let leads = response.data.Lead.map(el => {
            var o = Object.assign({}, el);
            if (
              "internal_admin" in o &&
              Object.entries(o.internal_admin).length === 0
            ) {
              o.internal_broker = o.internal_admin;
            }
            if (!("observations_detail" in o)) {
              o.observations_detail = {
                message: o.observations ? o.observations : "",
                link: "",
                category: "",
                interested_unit: "",
                contact_link: ""
              };
            } else if (
              "observations_detail" in o &&
              o.observations_detail.message === ""
            ) {
              o.observations_detail.message === o.observations
                ? o.observations
                : "";
            }
            return o;
          });
          resolve({ Lead: leads });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// obtiene la información de un lead
export function GetLeadDetail(leadId) {
  try {
    if (!leadId) return Promise.reject("No se especificó leadId");

    let data = {
      leadId: leadId
    };

    return new Promise((resolve, reject) => {
      request("/lead/getLeadDetail", data)
        .then(response => {
          let lead = response.data.Lead;
          lead.IsDuplicated = response.data.IsDuplicated
            ? response.data.IsDuplicated
            : false;
          if (!("observations_detail" in lead)) {
            lead.observations_detail = {
              message: lead.observations,
              link: "",
              category: "",
              interested_unit: "",
              contact_link: ""
            };
          } else if (
            "observations_detail" in lead &&
            lead.observations_detail.message === ""
          ) {
            lead.observations_detail.message = lead.observations
              ? lead.observations
              : "";
          }

          let parsedLead = parseContactLead([lead])[0];
          resolve({
            Lead: parsedLead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetCompleteLeadSurvey(leadId) {
  try {
    if (!leadId) {
      return Promise.reject("No se especificó leadId");
    } else {
      return new Promise((resolve, reject) => {
        request("/profileApp/getCompleteLeadSurvey", {
          leadId: leadId
        })
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  } catch (err) {
    return Promise.reject(err);
  }
}
export function SendNextQuestion(leadId, questionId, answer) {
  try {
    if (!leadId) {
      return Promise.reject("No se especificó leadId");
    } else {
      return new Promise((resolve, reject) => {
        request("/profileApp/nextQuestion", {
          leadId: leadId,
          currentQuestion: questionId,
          currentAnswer: answer
        })
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

// registra un nuevo lead
export function CreateLead(lead) {
  try {
    let data = {};
    let estimatedTimeParsed = "no-urgency";

    if (lead.estimatedTime && lead.estimatedTime.length > 0) {
      switch (lead.estimatedTime) {
        case "Urgente":
          estimatedTimeParsed = "urgent";
          break;
        case "Está listo":
          estimatedTimeParsed = "ready";
          break;
        case "Está viendo opciones":
          estimatedTimeParsed = "looking-options";
          break;
        case "Sin urgencia":
          estimatedTimeParsed = "no-urgency";
          break;
        default:
          estimatedTimeParsed = "no-urgency";
      }
    }

    data = {
      contact_lead_name: lead.name,
      email: lead.email,
      phone: lead.phone,
      location: lead.location,
      contact_lead_id: lead.contactLeadId,
      contact_broker_id: lead.contactBrokerId,
      contact_method: lead.contactMethod,
      profile: lead.profile,
      budget: lead.budget,
      currency: lead.currency,
      payment_method: lead.paymentMethod,
      target_action: lead.targetAction,
      property_type: lead.propertyType,
      zones: lead.zones,
      send_notification: 1,
      source_name: lead.source_name,
      medium_name: lead.medium_name,
      contact: {
        target_client: lead.targetClient,
        how_did_contact_us: lead.howDidContactUs,
        contact_method: lead.contactMethod,
        search_partner: lead.searchPartner,
        estimated_time: estimatedTimeParsed,
        availability_tour: lead.availabilityTour
      },
      language: lead.language,
      registration: "manual",
      observations: lead.observations,
      tracking_phase: lead.tracking_phase,
      operation_phase: lead.operation_phase,
      crm_link: lead.crmLink,
      status: 1,

      ...(lead.register_external && {
        register_external: true,
        real_estate_name: lead.real_estate_name,
        external_broker_name: lead.external_broker_name,
        external_broker_phone: lead.external_broker_phone,
        external_broker_email: lead.external_broker_email,
        internal_broker_name: lead.internal_broker_name
      })
    };

    return new Promise((resolve, reject) => {
      request("/lead/create", data)
        .then(response => {
          resolve({
            Lead: response.data.Lead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// actualiza información de un lead este es que tiene los observations detaol
export function UpdateLead(leadId, dataLead) {
  try {
    if (!leadId) return Promise.reject("No se especificó leadId");

    let data = {
      lead_id: leadId,
      lead: {}
    };
    if (dataLead.budget >= 0) {
      data.lead.budget = dataLead.budget;
    }
    if (dataLead.currency && dataLead.currency.length > 0) {
      data.lead.currency = dataLead.currency;
    }
    if (dataLead.observations_detail !== null) {
      data.lead.observations_detail = dataLead.observations_detail;
    }
    if (dataLead.comments && dataLead.comments.length > 0) {
      data.lead.comments = dataLead.comments;
    }
    if (dataLead.zones && dataLead.zones.length > 0) {
      data.lead.zones = dataLead.zones;
    }
    if (dataLead.created_at && dataLead.created_at.length > 0) {
      data.lead.created_at = dataLead.created_at;
    }
    if (dataLead.contact_lead_name && dataLead.contact_lead_name.length > 0) {
      data.lead.contact_lead_name = dataLead.contact_lead_name;
    }
    if (dataLead.profile && dataLead.profile.length > 0) {
      data.lead.profile = dataLead.profile;
    }
    if (dataLead.contactBrokerId && dataLead.contactBrokerId.length > 0) {
      if (localStorage.getItem("user_role") !== "broker") {
        data.lead.contact_broker_id = dataLead.contactBrokerId;
      } else {
        data.lead.contact_broker_id = localStorage.getItem("contact_id");
      }
    }
    if (dataLead.phase && dataLead.phase.length > 0) {
      data.lead.phase = dataLead.phase;
      data.lead.tracking_phase = dataLead.tracking_phase;
      data.lead.operation_phase = dataLead.operation_phase;
    }
    if (
      dataLead.discard_observations &&
      dataLead.discard_observations.length > 0
    ) {
      data.lead.discard_observations = dataLead.discard_observations;
    }
    if (dataLead.phase && dataLead.phase.length > 0) {
      data.lead.phase = dataLead.phase;
      // data.lead.tracking_phase = dataLead.tracking_phase;
      // data.lead.operation_phase = dataLead.operation_phase;
    }
    if (dataLead.tracking_phase) {
      data.lead.tracking_phase = dataLead.tracking_phase;
    }
    if (dataLead.operation_phase) {
      data.lead.operation_phase = dataLead.operation_phase;
    }
    if (dataLead.postponed) {
      data.lead.postponed = dataLead.postponed;
    }
    if (dataLead.contact) {
      data.lead.contact = dataLead.contact;
    }
    if (dataLead.reviewed) {
      data.lead.reviewed = dataLead.reviewed;
    }
    if (dataLead.language) data.lead.contact.language = dataLead.language;
    return new Promise((resolve, reject) => {
      request("/lead/update", data)
        .then(response => {
          resolve({
            Lead: response.data.Lead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// cambia el formato del objecto lead, los atributos dentro de contact_lead pasan al nivel dentro del lead
// ej lead.contact_lead.email => lead.contact_lead_email

function parseContactLead(leads) {
  leads.map(l => {
    l.contact_lead_name = l.contact_lead.name || "";
    l.contact_lead_phone = l.contact_lead.phone || "";
    l.contact_lead_email = l.contact_lead.email || "";
    l.contact_lead_location = l.contact_lead.location || "";
    l.budget = l.budget || "";
    l.zones = l.zones || "";
    l.observations = l.observations || "";
  });
  return leads;
}

export function GetLeadStats() {
  try {
    return new Promise((resolve, reject) => {
      request("/lead/stats")
        .then(response => {
          resolve({
            response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function GetLeadsDuplicados(lead_id) {
  try {
    return new Promise((resolve, reject) => {
      request("/contactLead/searchDuplicateByLead", { lead_id: lead_id })
        .then(response => {
          resolve({ response });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
