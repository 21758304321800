import {
  fetchInmos,
  fetchInmoDetail,
  fetchDevelopments,
  fetchDevelopmentsByInmoId,
  fetchUsers,
  fetchUsersByInmoId,
  createUser,
  createInmo,
  fetchCarrusel,
  createCarrusel,
  updateInmo,
  deleteCarousel,
  fetchLeads,
  fetchTraffic,
  fetchSourceAndMediums,
  deleteTraffic,
  createTraffic,
  deleteLead,
  changeRoleUser
} from "../../api/mnt-api";

export default {
  namespaced: true,
  state: {
    inmosList: getListInmosByLocalStorage(),
    developmentsList: [],
    usersList: [],
    //* inmoInspect
    inmoInspect: {},
    loadingInmoInspect: false,
    devsByInmoInspect: [],
    usersByInmoInspect: [],
    carouselList: [],
    leadsList: [],
    paginationLeads: {
      cursor: "",
      limit: 10,
      total: 0,
      page: 1,
      loading: false
    },
    trafficListByInmo: [],
    sources: [],
    mediums: []
  },
  getters: {
    // inmos
    // se usa para saber de que inmo es un desarrollo o un usuario
    getInmoById: state => id => {
      return state.inmosList.find(inmo => inmo._id === id);
    },
    // developments
    getDevelopmentsByInmo: state => inmoId => {
      return state.developmentsList.filter(
        dev => dev.real_estate_group_id === inmoId
      );
    },
    // users
    getAllUsers: state => {
      return state.usersList;
    },
    getUsersByInmoInspect: state => {
      return state.usersByInmoInspect;
    },
    // inmoInspect
    getInmoInspect: state => {
      return state.inmoInspect;
    },
    getDevelopmentsByInmoInspect: state => {
      return state.devsByInmoInspect;
    },
    getInmoLeads: state => {
      return state.leadsList;
    },
    sourcesByInmo: state => {
      return state.trafficListByInmo.reduce((acc, item) => {
        if ("source" in item) acc.push(item);
        return acc;
      }, []);
    },
    mediumsByInmo: state => {
      return state.trafficListByInmo.reduce((acc, item) => {
        if ("medium" in item) acc.push(item);
        return acc;
      }, []);
    },
    getPaginationLeads: state => {
      return state.paginationLeads;
    }
  },
  mutations: {
    setInmosList(state, data) {
      if (data.length > 0) {
        state.inmosList = data;
        localStorage.setItem("inmosList", JSON.stringify(data));
      }
    },
    setDevelopmentsList(state, data) {
      state.developmentsList = data;
    },
    setUsersList(state, data) {
      state.usersList = data;
    },
    setInmoInspect(state, data) {
      state.inmoInspect = data;
      state.carouselList = [];
      state.devsByInmoInspect = [];
      state.usersByInmoInspect = [];
      state.leadsList = [];
      state.trafficListByInmo = [];
    },
    setLoadingInmoInspect(state, data) {
      state.loadingInmoInspect = data;
    },
    setDevelopmentsByInmoInspect(state, data) {
      state.devsByInmoInspect = data;
    },
    setUsersByInmoInspect(state, data) {
      state.usersByInmoInspect = data;
    },
    addUser(state, user) {
      state.usersList.unshift(user);
      if (
        state.usersByInmoInspect &&
        state.inmoInspect._id === user.contactData.real_estate_group_id
      ) {
        state.usersByInmoInspect.unshift(user);
      }
    },
    addInmo(state, inmo) {
      state.inmosList.unshift(inmo);
    },
    setCarouselList(state, data) {
      state.carouselList = data;
    },
    removeCarousel(state, id) {
      const index = state.carouselList.findIndex(item => item._id === id);

      state.carouselList.splice(index, 1);
    },
    setLeadList(state, data) {
      state.leadsList = data;
    },
    pushLeadList(state, data) {
      state.leadsList.push(...data);
    },
    removeItemInLeadList(state, id) {
      const index = state.leadsList.findIndex(l => l._id === id);
      if (index >= 0) state.leadsList.splice(index, 1);
    },
    setTrafficListByInmo(state, data) {
      state.trafficListByInmo = data;
    },
    setTrafficList(state, data) {
      if (!data) return;
      state.sources = data.sources;
      state.mediums = data.mediums;
    },
    removeTraffic(state, id) {
      const index = state.trafficListByInmo.findIndex(item => item._id === id);
      state.trafficListByInmo.splice(index, 1);
    },
    addTrafficListByInmo(state, data) {
      state.trafficListByInmo.push(data);
    },
    setPagination(state, data) {
      state.paginationLeads.cursor = data.cursor;
      state.paginationLeads.total = data.total;
    },
    setLimit(state, data) {
      const posActual =
        state.paginationLeads.page * state.paginationLeads.limit;
      const newPage = Math.ceil(
        (posActual > state.leadsList.length
          ? state.leadsList.length
          : posActual) / data
      );
      state.paginationLeads.limit = data;
      state.paginationLeads.page = newPage;
    },
    setPage(state, data) {
      state.paginationLeads.page = data;
    },
    setLoading(state, val) {
      state.paginationLeads.loading = val;
    },
    setRoleUser(state, { id, role }) {
      const user = state.usersList.find(user => user._id === id);
      if (user) user.role = role;
      const userInInmo = state.usersByInmoInspect.find(user => user._id === id);
      if (userInInmo) userInInmo.role = role;
    }
  },
  actions: {
    async fetchInmosList({ commit }, payload) {
      const json = await fetchInmos(payload);
      commit("setInmosList", json.data);
    },
    async fetchDevelopmentsList({ commit }) {
      const json = await fetchDevelopments();
      commit("setDevelopmentsList", json.data);
    },
    async fetchUsersList({ commit }) {
      const json = await fetchUsers();
      commit("setUsersList", json.data);
    },
    async fetchInmoInspect({ commit }, id) {
      commit("setLoadingInmoInspect", true);
      const inmo = await fetchInmoDetail(id);
      commit("setInmoInspect", inmo.data);
      commit("setLoadingInmoInspect", false);
    },
    async fetchDevelopmentsByInmo({ commit }, inmoId) {
      const devs = await fetchDevelopmentsByInmoId(inmoId);
      commit("setDevelopmentsByInmoInspect", devs.data);
    },
    async fetchUsersByInmo({ commit }, inmoId) {
      const users = await fetchUsersByInmoId(inmoId);
      commit("setUsersByInmoInspect", users.data);
      return users.data;
    },
    async createUser({ commit }, user) {
      const newUser = await createUser(user);
      if (!newUser?.data) return newUser.Message;
      commit("addUser", newUser.data);
      return newUser;
    },
    async createInmo({ commit }, inmo) {
      const newInmo = await createInmo(inmo);

      if (!newInmo?.data) return newInmo.Message;
      commit("addInmo", newInmo.data);
      return newInmo;
    },
    async fetchCarrusels({ state, commit }, id) {
      if (state.inmoInspect?._id) id = state.inmoInspect._id;
      const carrusel = await fetchCarrusel(id);
      if (!carrusel?.data) return carrusel.Message;
      commit("setCarouselList", carrusel.data);
      return carrusel;
    },
    async createCarrusel({ state }, { carrusel, inmoId }) {
      if (!inmoId) inmoId = state.inmoInspect._id;
      const newCarrusel = await createCarrusel(inmoId, carrusel);
      if (!newCarrusel?.data) return newCarrusel.Message;
      return newCarrusel;
    },
    async updateInmo({ commit }, { id, inmo }) {
      //commit("setLoadingInmoInspect", true);
      const updatedInmo = await updateInmo(id, inmo);
      if (!updatedInmo?.data) return updatedInmo.Message;
      commit("setInmoInspect", updatedInmo.data);
      //commit("setLoadingInmoInspect", false);
      return updatedInmo;
    },
    async deleteCarouselByinmo({ commit }, { inmoId, carouselId }) {
      const res = await deleteCarousel(inmoId, carouselId);

      if (!res.data) return res.Message;
      commit("removeCarousel", carouselId);
      return res;
    },
    async fetchListLeadsByInmo({ commit, state }, { inmoId, filters, search }) {
      const listEmpty = state.leadsList.length === 0;
      const nameCommit = listEmpty ? "setLeadList" : "pushLeadList";
      const limit = listEmpty
        ? state.paginationLeads.limit * 2
        : state.paginationLeads.limit;
      // cuando se monta se pide el limit * 2 para evitar bugs en la paginación
      const pagination = {
        ...state.paginationLeads,
        limit
      };
      commit("setLoading", true);
      const res = await fetchLeads(inmoId, pagination, filters, search);
      commit("setLoading", false);
      if (!res.data) return res.Message;
      commit(nameCommit, res.data.leads);
      commit("setPagination", { ...res.data });
      return res;
    },
    async fetchTrafficByInmo({ commit }, inmoId) {
      const res = await fetchTraffic(inmoId);
      if (!res.data) return res.Message;
      commit("setTrafficListByInmo", res.data);
      return res;
    },
    async fetchSourceAndMediums({ commit }) {
      const res = await fetchSourceAndMediums();
      if (!res.data) return res.Message;
      commit("setTrafficList", res.data);
      return res;
    },
    async deleteTraffic({ commit }, { inmoId, trafficId }) {
      const res = await deleteTraffic({ inmoId, trafficId });
      if (!res.data) return res.Message;
      commit("removeTraffic", res.data._id);
      return res;
    },
    async createTraffic({ commit }, { inmoId, id, type }) {
      const res = await createTraffic(inmoId, { id, type });
      if (!res.data) return res.Message;
      commit("addTrafficListByInmo", res.data);
      return res;
    },
    resetPagination({ commit }) {
      commit("setPagination", {
        cursor: "",
        total: 0
      });
      commit("setLimit", 10);
      commit("setPage", 1);
      commit("setLeadList", []);
    },
    async deleteLead({ commit }, { leadId }) {
      const res = await deleteLead(leadId);
      if (!res.data) return res.Message;
      commit("removeItemInLeadList", leadId);
      return res;
    },
    async changeRoleUser({ commit }, { id, role }) {
      const res = await changeRoleUser({ userId: id, role });
      if (!res.data) return res.Message;
      commit("setRoleUser", { id, role });
      return res;
    }
  }
};

function getListInmosByLocalStorage() {
  const inmosList = localStorage.getItem("inmosList");
  if (inmosList) return JSON.parse(inmosList);
  return [];
}
