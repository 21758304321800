<template>
  <div>
    <v-select
      :dense="dense"
      outlined
      v-model="selectedDateFilter"
      :items="dateFilters"
      :label="label"
      @change="handleDateChange"
    ></v-select>
    <v-fade-transition>
      <div v-if="showDatePicker">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="customDateRange"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Rango de Fecha Personalizado"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="customDateRange" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="handleCustomDate">
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    default: {
      type: String,
      default: "Todas"
    },
    options: {
      type: Array,
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Selecciona un filtro de fecha"
    }
  },
  data() {
    return {
      selectedDateFilter: this.default,
      menu: false,
      dateRangeText: "",
      customDateRange: [],
      dateFilters: this.options.length
        ? this.options
        : [
            "Todas",
            "Ayer",
            "Hoy",
            "Últimos 7 días",
            "Últimos 30 días",
            "Mes pasado",
            "Este mes",
            "Año actual",
            "Personalizado"
          ]
    };
  },
  mounted() {
    this.handleDateChange();
  },
  computed: {
    showDatePicker() {
      return this.selectedDateFilter === "Personalizado";
    }
  },
  methods: {
    handleDateChange() {
      let startDate = new Date();
      let endDate = new Date();

      switch (this.selectedDateFilter) {
        case "Ayer":
          startDate.setDate(startDate.getDate() - 1);
          endDate.setDate(endDate.getDate() - 1);
          break;
        case "Hoy":
          // Las fechas ya están configuradas para hoy
          break;
        case "Últimos 7 días":
          startDate.setDate(startDate.getDate() - 7);
          break;
        case "Últimos 30 días":
          startDate.setDate(startDate.getDate() - 30);
          break;
        case "Mes pasado":
          startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() - 1,
            1
          );
          endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
          break;
        case "Este mes":
          startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            1
          );
          break;
        case "Año actual":
          startDate = new Date(startDate.getFullYear(), 0, 1);
          break;
        case "Personalizado":
          //this.menu = true;
          break;
        case "Todas":
        default:
          startDate = "";
          endDate = "";
      }

      this.applyDateRange(startDate, endDate);
    },
    handleCustomDate() {
      this.menu = false;
      if (this.customDateRange.length === 2) {
        const [start, end] = this.customDateRange;
        this.applyDateRange(new Date(start), new Date(end));
      }
    },
    applyDateRange(start, end) {
      if (start) {
        // Ajustar para la zona horaria local
        start = this.adjustDateForTimezone(start);
        end = this.adjustDateForTimezone(end);

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        this.dateRangeText = `${this.formatDate(start)} - ${this.formatDate(
          end
        )}`;
      }
      // emitir el evento para que el componente padre pueda manejar el rango de fechas
      this.$emit("input", { start, end });
    },
    formatDate(date) {
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const monthName = date.toLocaleString("es", { month: "short" });
      const month = monthName.charAt(0).toUpperCase() + monthName.slice(1);
      const year = date.getFullYear();
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${month} ${day}, ${year} ${hours}:${minutes}`;
    },
    adjustDateForTimezone(date) {
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() + userTimezoneOffset);
    },
    resetDateRange() {
      this.selectedDateFilter = this.default;
      this.dateRangeText = "";
      this.menu = false;
      this.customDateRange = [];
      this.handleDateChange();
    }
  }
};
</script>
