import authHeader from "./auth-header";
// import axios from "axios";

const API_URL = process.env.VUE_APP_API_ENDPOINT;

// auxiliar para generar peticionas a la API de C28
export default function request(endpoint, bodyRequest = {}) {
  return new Promise((resolve, reject) => {
    fetch(API_URL + endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(bodyRequest)
    })
      .then(res => res.json())
      .then(json =>
        resolve({
          data: json.Data != undefined ? json.Data : json
        })
      )
      .catch(err => {
        reject(err);
      });
  });
}

export async function requestV2({
  endpoint,
  bodyRequest = {},
  defaultResponse = null
}) {
  try {
    const respose = await fetch(API_URL + endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(bodyRequest)
    });
    const json = await respose.json();
    return json;
  } catch (err) {
    return {
      message: "Error en la peticion",
      error: err,
      data: defaultResponse
    };
  }
}
