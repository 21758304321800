<template>
  <!-- Los usuarios rool y developer no es soportado
  por el componente SelectableRole ni por el endpoint de cambio de rol 
  -->
  <div
    v-if="role === 'root' || role === 'developer'"
    elevation
    class="body-2 text-capitalize grey lighten-4 rounded-md px-2 py-2 text-center"
  >
    {{ role }}
  </div>

  <SelectableRole v-else v-model="value" :ommits="['root', 'developer']" />
</template>

<script>
import SelectableRole from "../components/SelectableRole.vue";
import { mapActions } from "vuex";
export default {
  name: "ChangeRoleUser",
  components: {
    SelectableRole
  },
  props: {
    role: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      value: this.role,
      previousValue: this.role
    };
  },
  computed: {},
  watch: {
    value(newVal) {
      // exceptuamos el cambio si el valor es igual al anterior para que no
      // se cicle al cancelar el cambio
      if (newVal === this.previousValue) return;
      this.$confirmation(
        "Confirmas Cambio de Rol",
        "Esta accion no se puede deshacer",
        () => this.changeRole(),
        () => this.cancelChange()
      );
    },
    role(newVal) {
      this.value = newVal;
      this.previousValue = newVal;
    }
  },
  methods: {
    ...mapActions("root", ["changeRoleUser"]),
    async changeRole() {
      const res = await this.changeRoleUser({
        id: this.userId,
        role: this.value
      });

      // si no se pudo cambiar el rol, regresamos al anterior
      if (!res.data) {
        this.value = this.previousValue;
        return this.$snotify.error(
          res || "Error al cambiar el rol",
          "Error :|"
        );
      }
      // si se cambio el rol, actualizamos el valor anterior
      this.previousValue = this.value;
      this.$snotify.success(
        res.Message || "Rol cambiado correctamente",
        "Completado :)"
      );
    },
    cancelChange() {
      this.value = this.previousValue;
    }
  }
};
</script>
