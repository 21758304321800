import RootMiddleware from "../middlewares/root-middleware";
import RootDashboard from "../views/root/dashboard.vue";
import ListInmos from "../views/root/ListInmos.vue";
import ListDevelopments from "../views/root/ListDevelopments.vue";
import ListUsers from "../views/root/ListUsers.vue";
import RealEstateGroupDetail from "../views/root/RealEstateGroupDetail.vue";

const slug = "/root";
const role = "Root";

const routes = [
  {
    path: slug,
    redirect: `${slug}/dashboard`,
    name: role
  },
  {
    path: `${slug}/dashboard`,
    name: `${role}dashboard`,
    component: RootDashboard,
    meta: {
      middleware: [RootMiddleware],
      title: "Root Dashboard"
    }
  },
  {
    path: `${slug}/inmos`,
    name: `${role}inmos`,
    component: ListInmos,
    meta: {
      middleware: [RootMiddleware],
      title: "Inmos"
    }
  },
  {
    path: `${slug}/inmos/:id`,
    name: `${role}detailInmo`,
    component: RealEstateGroupDetail,
    meta: {
      middleware: [RootMiddleware],
      title: "Real Estate Group Detail"
    }
  },
  {
    path: `${slug}/developments`,
    name: `${role}developments`,
    component: ListDevelopments,
    meta: {
      middleware: [RootMiddleware],
      title: "Developments"
    }
  },
  {
    path: `${slug}/users`,
    name: `${role}users`,
    component: ListUsers,
    meta: {
      middleware: [RootMiddleware],
      title: "Users"
    }
  }
];

export default routes;
