<template>
  <article>
    <header>
      <h2>Fuentes</h2>
      <SelectSources />
    </header>

    <section class="container__traffics-by-inmo">
      <CardTraffic
        class="items"
        v-for="item in sourcesByInmo"
        :key="item._id"
        :_id="item._id"
        :name="item.source.name"
        :media="item.source.media"
        type="source"
      />
    </section>
    <v-divider></v-divider>
    <header>
      <h2>Medios</h2>
      <SelectMediums />
    </header>
    <section class="container__traffics-by-inmo">
      <CardTraffic
        class="items"
        v-for="item in mediumsByInmo"
        :key="item._id"
        :_id="item._id"
        :name="item.medium.name"
        :media="item.medium.media"
        type="medium"
      />
    </section>
  </article>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardTraffic from "./CardTraffic.vue";
import SelectSources from "../common/SelectSources.vue";
import SelectMediums from "../common/SelectMediums.vue";
export default {
  name: "TrafficsByInmo",
  props: {
    inmoId: {
      type: String,
      required: true
    }
  },
  components: {
    CardTraffic,
    SelectSources,
    SelectMediums
  },
  data() {
    return {};
  },
  created() {
    this.fetchTrafficByInmo(this.inmoId);
    this.fetchSourceAndMediums();
  },
  computed: {
    ...mapGetters("root", {
      sourcesByInmo: "sourcesByInmo",
      mediumsByInmo: "mediumsByInmo",
      sources: "sources",
      mediums: "mediums"
    })
  },
  methods: {
    ...mapActions("root", {
      fetchTrafficByInmo: "fetchTrafficByInmo",
      fetchSourceAndMediums: "fetchSourceAndMediums"
    })
  }
};
</script>

<style scoped>
.container__traffics-by-inmo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

span {
  border: 1px solid #ccc;
  margin-bottom: 0 !important;
  padding: 0.25rem 0.5rem;

  border-radius: 0.5rem;
}
header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}
</style>
