import request from "../helpers/request";
// registra una nueva amenidad
export function CreateAmenities(amenities) {
  try {
    let data = {};

    data = {
      media: amenities.media,
      construction: amenities.construction,
      miscellaneous: amenities.miscellaneous,
      gallery: amenities.gallery,
      status: 1,
      contract_status: amenities.contract_status,
      name: amenities.name,
      code: amenities.code,
      abbreviation: amenities.abbreviation,
      alias: amenities.alias,
      floor: amenities.floor,
      pricing: amenities.pricing,
      real_estate_development: amenities.real_estate_development,
      property: amenities.property
    };

    return new Promise((resolve, reject) => {
      request("/amenities/create", data)
        .then(response => {
          resolve({
            Amenitie: response.data.amenitie
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
