import request from "../helpers/request";

const API_URL = process.env.VUE_APP_API_ENDPOINT;

export function GetContactBrokerListRespaldo(type) {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  let access_group = payload.access_group;
  let dataRequest = {
    access_group: access_group
  };
  if (type) {
    dataRequest.type = type;
  }
  return fetch(API_URL + "/ContactBroker/List", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken
    },
    body: JSON.stringify(dataRequest)
  })
    .then(res => res.json())
    .then(json => json.Data);
}
// por reemplazar a función al subir a dev.api
export function GetContactBrokerList() {
  let dataRequest = {};
  dataRequest.type = "internal";
  return new Promise((resolve, reject) => {
    request("/Contacts/List", dataRequest)
      .then(response => {
        resolve({
          ContactBroker: response.data.ContactsList
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}
