import { CreateAmenities } from "@/api/amenities-api.js";

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {},
  mutations: {
    setLoading(state, loading) {
      // establece que se esta cargando información
      state.loading = loading;
    }
  },
  actions: {
    fetchCreateAllAmenities({ commit }, dtAmenities) {
      try {
        let promises = [];
        if (dtAmenities.length === 0)
          throw new Error("No se especificó los parametros");
        // if (!realEstateDevelopment)
        //   throw new Error("Datos del desarrollo no esta especificado");

        let dataAmenities = {};

        dtAmenities.map(amt => {
          dataAmenities = {
            name: amt.type,
            code: amt.code,
            abbreviation: amt.abbreviation,
            alias: amt.alias,
            pricing: {
              price: amt["pricing/price"],
              currency: amt["pricing/currency"]
            },
            // desarrollo que pertenece
            real_estate_development: {
              _id: amt.real_estate_development._id,
              code: amt.real_estate_development.code,
              name: amt.real_estate_development.name
            },
            floor: {
              code: amt["floor/code"],
              name: amt["floor/name"]
            },
            // información estructura de la amenidad
            construction: {
              unit: amt["construction/unit"],
              total: amt["construction/total"]
            },
            // amenidades de la unidad
            property: {
              _id: amt["property/_id"],
              code: amt["property/code"]
            },
            // información media
            media: {
              featured_image: {
                name: amt["media/featured_image/name"],
                alt_text: amt["media/featured_image/alt_text"],
                src: amt["media/featured_image/src"]
              },
              icono: {
                name: amt["media/icono/name"],
                alt_text: amt["media/icono/alt_text"],
                src: amt["media/icono/src"]
              }
            }
          };
          if (amt.contract_status) {
            dataAmenities.contract_status = amt.contract_status;
          } else {
            dataAmenities.contract_status = "indefinido";
          }
          dataAmenities.miscellaneous = {};
          if (amt["miscellaneous/mapping_coords"]) {
            const arrayMapCoodrs = amt["miscellaneous/mapping_coords"];
            dataAmenities.miscellaneous.mapping_coords = arrayMapCoodrs
              .split(",")
              .map(i => Number(i, 10));
          }
          if (amt["miscellaneous/mapping_coords_building"]) {
            const arrayMapCoodrsBuilding =
              amt["miscellaneous/mapping_coords_building"];
            dataAmenities.miscellaneous.mapping_coords_building = arrayMapCoodrsBuilding
              .split(",")
              .map(i => Number(i, 10));
          }
          promises.push(CreateAmenities(dataAmenities));
        });

        if (promises.length === 0) return Promise.reject("Nada por editar");

        return Promise.all(promises)
          .then(values => {
            // actualizar tabla de prpiedades
            commit("setLoading", true);
            return Promise.resolve(values);
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
};
