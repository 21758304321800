<template>
  <v-card>
    <h1>Users view</h1>
    <v-card-title>
      <UserFormModal type="create" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by Nombre, Telefono y Role."
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <TableUsers :users="users" :search="search" :loading="loading" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RootLayout from "../../components/layouts/RootLayout.vue";
import TableUsers from "../../components/root/TableUsers.vue";
import UserFormModal from "../../components/root/forms/ModalUserV2.vue";

export default {
  name: "ListUsers",
  components: {
    TableUsers,
    UserFormModal
  },
  async created() {
    this.$emit(`update:layout`, RootLayout);
    this.loading = true;
    await this.fetchUsers();
    this.loading = false;
  },
  data() {
    return {
      search: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters("root", {
      users: "getAllUsers"
    })
  },
  methods: {
    ...mapActions("root", {
      fetchUsers: "fetchUsersList"
    })
  }
};
</script>
