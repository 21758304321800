<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        :icon="btnOpen.onlyIcon"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ btnOpen.icon }}</v-icon>
        {{ btnOpen.text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="name"
                label="Nombre*"
                required
                :rules="nameRules"
                :readonly="isReadOnly"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="phone"
                label="Telefono"
                hint="example of helper text only on focus"
                :readonly="isReadOnly"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="Email*"
                required
                :rules="emailRules"
                :readonly="isReadOnly"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                label="Password*"
                required
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-show="isCreating"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="role"
                :items="roles"
                label="Role*"
                required
                :readonly="isReadOnly"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="inmos"
                label="Inmobiliaria*"
                v-model="real_estate_group_id"
                :disabled="role === 'developer'"
                :rules="rulesInmos"
                :readonly="isReadOnly"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <DevelopmentsSelectable
                v-model="developments"
                :real_estate_group_id="real_estate_group_id"
                :role="role"
                :isReadOnly="isReadOnly"
              />
            </v-col>
          </v-row>
        </v-form>
        <small>*Campos Requeridos</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">
          Cerrar
        </v-btn>
        <v-btn v-if="type !== 'view'" color="primary" text @click="saveUser()">
          {{ isEditing ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DevelopmentsSelectable from "../components/DevelopmentsSelectable.vue";

export default {
  name: "UserFormModal",
  components: {
    DevelopmentsSelectable
  },
  props: {
    defaultInmo: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String, // create | update | view
      required: false,
      default: "create"
    },
    currentUser: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      showPassword: false,
      real_estate_group_id: this.defaultInmo,
      roles: [
        {
          text: "Administrador",
          value: "admin"
        },
        {
          text: "Broker",
          value: "broker"
        },
        {
          text: "Marketing",
          value: "marketing"
        },
        {
          text: "Coordinador",
          value: "coordinator"
        },
        {
          text: "Desarrollador",
          value: "developer"
        },
        {
          text: "Marketing 2",
          value: "marketingv2"
        }
      ],
      developments: [],
      role: "",
      name: "",
      email: "",
      password: "",
      phone: "",
      nameRules: [
        v => !!v || "Name is required",
        v =>
          (v && v.length >= 3) || "El nombre debe tener al menos 3 caracteres"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Password must be at least 8 characters"
      ]
    };
  },
  created() {
    if (this.currentUser) this.loadUserData();
  },
  watch: {
    role(newVal) {
      if (newVal === "developer") {
        this.real_estate_group_id = "";
      }
    },
    dialog(val) {
      // cuando se abre el dialogo y es edición
      // se carga la información del usuario
      if (val && this.currentUser) this.loadUserData();
      // cuando se cierra el dialogo se resetea el formulario
      // no importa si es edición o creación
      if (!val) this.resetForm();
    }
  },
  computed: {
    ...mapState("root", {
      inmosList: state => state.inmosList
    }),
    inmos() {
      const inmos = this.inmosList.map(inmo => {
        return {
          text: inmo.name,
          value: inmo._id
        };
      });

      return inmos;
    },
    rulesInmos() {
      if (this.role === "developer") {
        return [];
      }
      return [v => !!v || "Inmobiliaria es requerida"];
    },
    btnOpen() {
      // return icon and text for button based on type
      return this.type === "create"
        ? { icon: "mdi-plus", onlyIcon: false, text: "Crear Usuario" }
        : this.type === "update"
        ? { icon: "mdi-pencil", onlyIcon: true, text: "" }
        : { icon: "mdi-eye", onlyIcon: true, text: "" };
    },
    title() {
      return this.type === "create"
        ? "Nuevo Usuario"
        : this.type === "update"
        ? "Editar Usuario"
        : "Ver Usuario";
    },
    isReadOnly() {
      return this.type === "view";
    },
    isEditing() {
      return this.type === "update";
    },
    isCreating() {
      return this.type === "create";
    }
  },
  methods: {
    ...mapActions("root", {
      createUser: "createUser"
      // updateUser: "updateUser" // Suponiendo que tienes una acción para actualizar
    }),
    validateForm() {
      this.$refs.form.validate();
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.phone = "";
      this.role = "";
      this.developments = [];
      this.real_estate_group_id = this.defaultInmo;
    },
    closeDialog() {
      this.dialog = false;
    },
    loadUserData() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.phone = this.currentUser.contactData.phone;
      this.role = this.currentUser.role;
      this.developments = this.currentUser.contactData.developments || [];
      this.real_estate_group_id = this.currentUser.contactData.real_estate_group_id;
    },
    async saveUser() {
      this.validateForm();

      if (this.valid) {
        const userPayload = {
          name: this.name,
          email: this.email,
          password: this.password,
          phone: this.phone,
          role: this.role,
          real_estate_group_id: this.real_estate_group_id,
          real_estate_develop_id: this.developments
        };

        let res;
        if (this.isEditing) {
          res = await this.updateUser(userPayload);
        } else {
          res = await this.createUser(userPayload);
        }

        if (res.data) {
          const message = this.isEditing
            ? "Usuario actualizado correctamente"
            : "Usuario creado correctamente";
          this.$snotify.success(message);
          this.closeDialog();
        } else {
          this.$snotify.error(`${res}`);
        }
      }
    },
    updateUser(payload) {
      // revisar que campos se deben enviar para actualizar
      const changes = this.getChangedFields(payload);
      return changes;
    },
    getChangedFields(formData) {
      const changes = {};
      Object.keys(formData).forEach(key => {
        if (
          JSON.stringify(formData[key]) !==
          JSON.stringify(this.currentUser[key])
        ) {
          changes[key] = formData[key];
        }
      });
      return changes;
    }
  }
};
</script>
