<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon small color="grey lighten-1">
          mdi-help-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <div>
      <div class="d-flex flex-row align-center">
        <span>Color de asignación</span>
      </div>
      <div class="d-flex flex-row align-center gap-1">
        <v-icon x-small color="green" class="mr-2">mdi-circle</v-icon>
        <span>Menos de 15 días</span>
      </div>
      <div class="d-flex flex-row align-center">
        <v-icon x-small color="orange" class="mr-2">mdi-circle</v-icon>
        <span>Menos de 30 días</span>
      </div>
      <div class="d-flex flex-row align-center">
        <v-icon x-small color="red" class="mr-2">mdi-circle</v-icon>
        <span>Más de 30 días</span>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "HeaderLastAsigned",
  data: () => ({})
};
</script>
