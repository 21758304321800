import request from "../helpers/request";
const API_URL = process.env.VUE_APP_API_ENDPOINT;

// reg = RealEstateGroup
export function CreateRealEstateGroup(reg) {
  let data = {};
  // object to insert
  data = {
    name: reg.name,
    contact_data: {
      web: reg.web,
      phone: reg.phone
    },
    media: {
      logo: {
        name: reg.name,
        title: reg.name,
        src: reg.logoSrc
      }
    },
    address: {
      full_address: reg.fullAddress
    }
  };
  return fetch(API_URL + "/RealEstateGroup/create", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => json.Data);
}

export function RealEstateGroupDetail() {
  try {
    return new Promise((resolve, reject) => {
      request("/RealEstateGroup/detail", {})
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function UpdateRealEstateGroup(body) {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  try {
    return new Promise((resolve, reject) => {
      request("/RealEstateGroup/update", {
        accessGroupoupId: payload.accessGroupoupId,
        realEstateGroupData: body
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
