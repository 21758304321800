import MarketingV2Middleware from "../middlewares/marketing-v2-middleware";
const slug = "/marketingv2";
const role = "MarketingV2";

const routes = [
  // momentaneo
  {
    path: slug,
    redirect: `${slug}/leads`,
    name: role
  },
  /* Leads */
  {
    path: `${slug}/leads`,
    name: `${role}LeadsView`,
    component: () => import("../views/lead/MarketingLeads"),
    meta: {
      middleware: [MarketingV2Middleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/list`,
    name: `${role}LeadListView`,
    component: () => import("../views/lead/MarketingLeadKanbanView"),
    redirect: `${slug}/leads`,
    meta: {
      middleware: [MarketingV2Middleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/kanban`,
    name: `${role}LeadKanbanView`,
    component: () => import("../views/lead/MarketingLeadKanbanView"),
    redirect: `${slug}/leads`,
    meta: {
      middleware: [MarketingV2Middleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/reports`,
    name: `${role}CampaignView`,
    component: () => import("../views/reports-campaings/TableReportKpis.vue"),
    meta: {
      middleware: [MarketingV2Middleware],
      title: "Reportes"
    }
  }
];

export default routes;
