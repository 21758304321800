//?La nueva ruta
import BrokerMiddleware from "../middlewares/broker-middleware";
import AdminAndBrokerMiddleware from "../middlewares/admin-and-broker-middleware";
const slug = "/broker";
const role = "Broker";

let routes = [
  //Default
  {
    path: "*",
    redirect: `${slug}/leads`,
    name: `${role}Default`
  },
  // momentaneo
  {
    path: slug,
    redirect: `${slug}/leads`,
    name: role
  },
  /* Desarrollos */
  {
    path: `${slug}/real-estate-development/list`,
    name: `${role}RealEstateDevelopmentView`,
    component: () =>
      import(
        "../views/real-estate-development/BrokerRealEstateDevelopmentView"
      ),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Desarrollos Inmobiliarios"
    }
  },
  /* Presentaciones de los brokers */
  {
    path: `${slug}/presentation/list`,
    name: `${role}PresentationView`,
    component: () => import("../views/presentation/BrokerPresentationView"),
    meta: {
      middleware: [AdminAndBrokerMiddleware],
      title: "Presentaciones a broker externo"
    }
  },
  {
    path: `${slug}/presentation/detail`,
    name: `${role}PresentationDetailView`,
    component: () =>
      import("../views/presentation/BrokerPresentationDetailView"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Presentación"
    }
  },
  /* Propiedades */
  {
    path: `${slug}/property/list`,
    name: `${role}PropertyView`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Propiedades"
    }
  },
  {
    path: `${slug}/:realEstateCode/property/list`,
    name: `${role}PropertyViewCode`,
    component: () => import("../views/property/AdminPropertyView"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Propiedades"
    }
  },
  /* Propiedades */
  /*  {
    path: `${slug}/property/list`,
    name: `${role}PropertyView`,
    component: () => import("../views/property/BrokerPropertyView"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Propiedades"
    }
  }, */
  /* Prospectos - Leads */
  {
    path: `${slug}/leads`,
    name: `${role}LeadsView`,
    component: () => import("../views/lead/BrokerLeads"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/list`,
    name: `${role}LeadListView`,
    redirect: `${slug}/leads`,
    component: () => import("../views/lead/BrokerLeadView"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Leads"
    }
  },
  {
    path: `${slug}/lead/kanban`,
    name: `${role}LeadKanbanView`,
    redirect: `${slug}/leads`,
    component: () => import("../views/lead/BrokerLeadKanbanView"),
    meta: {
      middleware: [BrokerMiddleware],
      title: "Leads"
    }
  }
];

if (localStorage.getItem("moduleQuote") === "on") {
  routes.push(
    /* Cotizador */
    {
      path: `${slug}/real-estate-development/quote/:code`,
      name: `${role}RealEstateDevelopmentQuoteView`,
      component: () =>
        import(
          "../views/real-estate-development/AdminRealEstateDevelopmentQuoteView"
        ),
      meta: {
        middleware: [BrokerMiddleware],
        title: "Cotizador"
      }
    },

    {
      path: `${slug}/real-estate-development/quote/:code/:property`,
      name: `${role}RealEstateDevelopmentQuoteViewProp`,
      component: () =>
        import(
          "../views/real-estate-development/AdminRealEstateDevelopmentQuoteView"
        ),
      meta: {
        middleware: [BrokerMiddleware],
        title: "Cotizador"
      }
    },
    /* Nuevo Cotizador avanzado.. */
    {
      path: `${slug}/real-estate-development/quoteAdvanced/:code`,
      name: `${role}RealEstateDevelopmentQuotePropertyView`,
      component: () =>
        import(
          "../views/real-estate-development/AdminRealEstateDevelopmentQuoteAdvancedView"
        ),
      meta: {
        middleware: [BrokerMiddleware],
        title: "Cotizador"
      }
    },
    /* Nuevo Cotizador avanzado con propiedad seleccionada.. */
    {
      path: `${slug}/real-estate-development/quoteAdvanced/:code/:property`,
      name: `${role}RealEstateDevelopmentQuotePropertyViewProp`,
      component: () =>
        import(
          "../views/real-estate-development/AdminRealEstateDevelopmentQuoteAdvancedView"
        ),
      meta: {
        middleware: [BrokerMiddleware],
        title: "Cotizador"
      }
    }
  );
}

export default routes;

// import BrokerMiddleware from "../middlewares/broker-middleware";
// const slug = "/broker";
// const role = "Broker";

// const routes = [
//   // momentaneo
//   {
//     path: slug,
//     redirect: `${slug}/lead/list`,
//     name: role
//   },
//   {
//     path: slug,
//     redirect: `${slug}/dashboard`,
//     name: role
//   },
//   /* Overview */
//   {
//     path: `${slug}/dashboard`,
//     name: `${role}Dashboard`,
//     component: () => import("../views/overview/BrokerDashboard.vue"),
//     meta: {
//       middleware: [BrokerMiddleware],
//       title: "Leads"
//     }
//   },
//   /* Desarrollos */
//   {
//     path: `${slug}/real-estate-development/list`,
//     name: `${role}RealEstateDevelopmentView`,
//     component: () =>
//       import(
//         "../views/real-estate-development/BrokerRealEstateDevelopmentView"
//       ),
//     meta: {
//       middleware: [BrokerMiddleware],
//       title: "Desarrollos Inmobiliarios"
//     }
//   },
//   /* Propiedades */
//   {
//     path: `${slug}/property/list`,
//     name: `${role}PropertyView`,
//     component: () => import("../views/property/BrokerPropertyView"),
//     meta: {
//       middleware: [BrokerMiddleware],
//       title: "Propiedades"
//     }
//   },
//   /* Prospectos - Leads */
//   {
//     path: `${slug}/lead/`,
//     name: `${role}LeadView`,
//     redirect: `${slug}/lead/list`
//   },
//   {
//     path: `${slug}/lead/list`,
//     name: `${role}LeadListView`,
//     component: () => import("../views/lead/BrokerLeadView"),
//     meta: {
//       middleware: [BrokerMiddleware],
//       title: "Leads"
//     }
//   },
//   {
//     path: `${slug}/lead/kanban`,
//     name: `${role}LeadKanbanView`,
//     component: () => import("../views/lead/BrokerLeadKanbanView"),
//     meta: {
//       middleware: [BrokerMiddleware],
//       title: "Leads"
//     }
//   }
// ];

// export default routes;
