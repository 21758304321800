<template>
  <v-container>
    <MoveUserSys />
  </v-container>
</template>

<script>
import RootLayout from "../../components/layouts/RootLayout.vue";
import MoveUserSys from "../../components/root/components/dashboard/MoveUserSys.vue";
export default {
  name: "RootDashboard",
  components: {
    MoveUserSys
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      }
    };
  },
  async created() {
    this.$emit(`update:layout`, RootLayout);
  },
  methods: {}
};
</script>

<style></style>
