<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h5">Carrusel</span>
      </v-card-title>
      <v-banner
        v-if="getStatus.text === 'Error'"
        class="text-body-2"
        color="yellow lighten-4"
        two-line
      >
        <v-icon slot="icon" large color="warning">
          mdi-alert-outline
        </v-icon>
        Advertencia: Los Leads que entren a este carrusel no serán asignados.
        <br />
        <span class="text-body-2"
          >No hay usuarios disponibles para asignar en este carrusel.</span
        >
      </v-banner>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                readonly
                hide-details=""
                label="Nombre"
                :value="carrousel.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                readonly
                hide-details=""
                label="Tipo de Asignación"
                :value="getTypeAsignation()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="text-subtitle-2">Usuarios:</span>
              <p
                v-if="carrousel.contact_list.length === 0"
                class="red lighten-4 pa-2"
              >
                No hay usuarios asignados a este carrusel
              </p>
              <v-virtual-scroll
                v-else
                :items="carrousel.contact_list"
                :item-height="50"
                :height="
                  carrousel.contact_list?.length >= 4
                    ? 200
                    : carrousel.contact_list?.length * 55
                "
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-avatar color="primary" size="30" class="white--text">
                        {{ item?.name?.substring(0, 2).toUpperCase() }}
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        item?.name || "No hay usuarios"
                      }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        v-model="item.status"
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-col>
            <v-col v-if="getTypeAsignation() === 'Multi-Campaña'" cols="12">
              <span class="text-subtitle-2">Campañas:</span>
              <v-chip-group
                v-model="campaign"
                column
                active-class=" deep-purple--text text--accent-4"
              >
                <v-chip
                  small
                  v-for="(campaign, index) in carrousel.name_aliases"
                  :key="index"
                  >{{ campaign }}</v-chip
                >
              </v-chip-group>
            </v-col>
          </v-row>
          <!-- Por terminar opcion de probar carrusel -->
          <!-- <span class="text-subtitle-2">Realizar Prueba:</span>
          <section class="grey lighten-3 rounded-lg py-4 container__code">
            <span class="icon__copy">
              <v-icon @click="copyText()">
                mdi-content-copy
              </v-icon></span
            >
            <pre id="code">
            {
              "campaign":"{{ nameCampaign }}",
              "carrusel":"on",
              "marketing_assigned":true
            }</pre
            >
          </section>
          <small
            >* Añade estos parametros en tu conexion para probar el
            carrusel</small
          > -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" disabled text @click="dialog = false">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getTypeAsignation, getStatus } from "../../utils/scripts/carrousel";
export default {
  name: "ModalCarrousel",
  props: {
    carrousel: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      campaign: this.carrousel?.name
    };
  },
  computed: {
    getStatus() {
      return getStatus(this.carrousel);
    },
    nameCampaign() {
      if (typeof this.campaign === "string") {
        return this.campaign;
      }
      return this.carrousel?.name_aliases[this.campaign];
    }
  },
  methods: {
    getTypeAsignation() {
      return getTypeAsignation(this.carrousel);
    },
    async copyText() {
      try {
        const id = "code";
        const text = document.getElementById(id).innerText;
        await navigator.clipboard.writeText(text);
        this.$snotify.success("Copiado al portapapeles");
      } catch (error) {
        this.$snotify.error("Error al copiar al portapapeles");
      }
    }
  }
};
</script>

<style>
.container__code {
  position: relative;
}
.container__code .icon__copy {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  visibility: hidden;
  margin: 0.5rem;
}

.container__code:hover .icon__copy {
  visibility: visible;
}
</style>
