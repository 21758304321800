<template>
  <span>
    <v-avatar size="20">
      <img :src="media.src" :alt="media.name" />
    </v-avatar>
    {{ name }}
    <!-- delete -->
    <v-icon dense @click="clickDelete">mdi-close-circle-outline</v-icon>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CardTraffic",
  components: {},
  props: {
    media: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    _id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      confirm: false,
      inmoId: this.$route.params.id
    };
  },
  computed: {
    nameType() {
      return this.type === "source" ? "Fuente" : "Medio";
    }
  },
  methods: {
    ...mapActions("root", {
      deleteTraffic: "deleteTraffic"
    }),
    clickDelete() {
      this.$confirmation(
        `Eliminar ${this.nameType}`,
        `¿Estás seguro de eliminar ${this.nameType} ${this.name}?`,
        () => this.deleteTraffic({ inmoId: this.inmoId, trafficId: this._id })
      );
    }
  }
};
</script>
