import {
  GetContactList,
  GetContactListAllocation,
  CreateContactListLocation,
  CreateUser,
  UpdateContactListAllocation,
  updateContactCarouselBrokerById,
  updateContactCarouselDeveloperById,
  updateContactCarouselAdminById,
  multiAssignedLeadstoContact
} from "@/api/contacts-api.js";
import { SendNotificationReassigned } from "@/api/notification-api.js";
export default {
  namespaced: true,
  state: {
    contactList: null,
    contactListAllocation: null,
    contactListLoading: false,
    multiAssigned: false
  },
  getters: {
    selectContactList: state => state.items,
    contactById: state => id => {
      return state.contactList.ContactsList.find(contact => contact._id === id);
    }
  },
  mutations: {
    setContactListLoading(state, v) {
      state.contactListLoading = v;
    },
    setContact(state, data) {
      state.contactList = data;
    },
    setContactById(state, data) {
      state.contactList = data;
    },
    setContactListAllocation(state, data) {
      state.contactListAllocation = data;
    },
    setmultiAssigned(state, data) {
      state.multiAssigned = data;
    }
  },
  actions: {
    fetchContactList({ commit }) {
      commit("setContactListLoading", true);
      try {
        return new Promise(resolve => {
          GetContactList()
            .then(res => {
              commit("setContact", res);
              commit("setContactListLoading", false);
              resolve(res);
            })
            .catch(error => {
              commit("setContactListLoading", false);
              throw new Error(error);
            });
        });
      } catch (error) {
        commit("setContactListLoading", false);
        return Promise.reject(error);
      }
    },
    fetchContactListAllocation({ commit }) {
      try {
        return new Promise(resolve => {
          GetContactListAllocation()
            .then(res => {
              commit("setContactListAllocation", res);
              resolve(res);
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    postCreateContactListLocation({ commit }, data) {
      try {
        return new Promise(resolve => {
          CreateContactListLocation(data)
            .then(() => {
              GetContactListAllocation()
                .then(res => {
                  commit("setContactListAllocation", res);
                  resolve(res);
                })
                .catch(error => {
                  throw new Error(error);
                });
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateContactListAllocation({ commit }, data) {
      try {
        return new Promise(resolve => {
          UpdateContactListAllocation(data)
            .then(() => {
              GetContactListAllocation().then(res => {
                commit("setContactListAllocation", res);
                resolve(res);
              });
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    createUser({ commit }, body) {
      commit("setContactListLoading", false);
      try {
        return new Promise(resolve => {
          CreateUser(body)
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateContactById({ commit }, data) {
      try {
        return new Promise(resolve => {
          switch (data.role) {
            case "broker":
              updateContactCarouselBrokerById(data.data)
                .then(res => {
                  resolve(res);
                })
                .catch(error => {
                  throw new Error(error);
                });
              break;
            case "admin":
              updateContactCarouselAdminById(data.data)
                .then(res => {
                  commit("setContact", res);
                  resolve(res);
                })
                .catch(error => {
                  throw new Error(error);
                });
              break;
            case "developer":
              updateContactCarouselDeveloperById(data.data)
                .then(res => {
                  resolve(res);
                })
                .catch(error => {
                  throw new Error(error);
                });
              break;
            default:
              break;
          }
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    reassignedLeadstoContact({ commit }, data) {
      try {
        return new Promise(resolve => {
          multiAssignedLeadstoContact(data)
            .then(res => {
              commit("setmultiAssigned", res);
              resolve(res);
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    notifyReassignedLeads(state, notifyData) {
      try {
        let data = {
          contact_id: notifyData.contactBrokerId,
          role: notifyData.role,
          nLeads: notifyData.nLeads
        };
        return new Promise(resolve => {
          // consultar API C28
          SendNotificationReassigned(data)
            .then(res => {
              if (res.Notifiers) resolve();
              resolve();
            })
            .catch(error => {
              throw new Error(error);
            });
        });
      } catch (error) {
        return Promise.reject;
      }
    }
  }
};
