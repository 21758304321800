import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import snotify from "./plugins/snotify";
import cloudinary from "./plugins/cloudinary";
import VueApexCharts from "vue-apexcharts";
import ConfirmationModal from "./plugins/ConfirmationModal";
// import chart from "vue2-frappe";

Vue.config.productionTip = false;

// Vue.use(chart);

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(ConfirmationModal, { vuetify });

new Vue({
  router,
  store,
  vuetify,
  snotify,
  cloudinary,
  render: h => h(App)
}).$mount("#app");
