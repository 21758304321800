import {
  GetProperties,
  GetPropertyDetail,
  UpdateProperty,
  CreateProperty
} from "@/api/property-api.js";

export default {
  namespaced: true,
  state: {
    items: [], // lista de todos las propiedades
    actualItem: {}, // usado al visualizar una sola propiedad
    loading: false,
    // filters
    filterOnlyAvailable: true,
    filterText: "",
    // rango de precios para el filtro max min
    // https://vuetifyjs.com/en/components/sliders/#range
    filterPriceRange: [0, 0],
    // obtener el precio mas alto (será segundo valor de filterPriceRange)
    itemsLowestPrice: 0,
    // obtener el precio mas bajo (será primer valor de filterPriceRange)
    itemsHighestPrice: 100000
  },
  getters: {
    // obtiene todos los leads con los filtros que estén activados
    filteredItems(state) {
      let filteredItems = state.items;
      // si tiene activado sólo propiedades disponibles
      // filtrar por contract_status disponible
      if (state.filterOnlyAvailable) {
        filteredItems = filteredItems.filter(i => {
          return i.contract_status.trim().toLowerCase() === "disponible";
        });
      }
      if (state.filterText) {
        let multipleSearch = state.filterText.split(" ");
        let matchingItems = filteredItems.filter(function(i) {
          // filtros de búsqueda, pasarlos a minúscula
          let code = "";
          let contractStatus = "";
          let realEstateDevelopmentName = "";
          let fullStrFields = "";

          // concatenar campos por los que se puede buscar
          if (i.code) {
            code = i.code.toLowerCase();
            fullStrFields += code + " ";
          }

          if (i.real_estate_development && i.real_estate_development.name) {
            realEstateDevelopmentName = i.real_estate_development.name.toLowerCase();
            fullStrFields += realEstateDevelopmentName + " ";
          }

          if (i.contractStatus) {
            contractStatus = i.contractStatus.toLowerCase();
            fullStrFields += contractStatus + " ";
          }

          // realizar búsqueda en el string con todos los campos
          // por cada palabra ingresada en el buscador
          let isMatch = multipleSearch.every(word =>
            fullStrFields.includes(word)
          );

          return isMatch;
        });
        filteredItems = matchingItems;
      }
      // if (state.filterPriceRange !== [0, 0]) {

      // }
      return filteredItems;
    }
  },
  mutations: {
    setProperties(state, properties) {
      // actualiza la información de las propiedades en Vuex
      state.items = properties;
      // aqui llamar a funcion para calcular precio más alto y precio más bajo
    },
    // actualiza la información de las propiedades en Vuex con el valor que se esta pasando
    setActualProperty(state, property) {
      state.actualItem = property;
    },
    resetActualProperty(state) {
      state.actualItem = {};
    },
    setFilterOnlyAvailable(state, value) {
      state.filterOnlyAvailable = value;
    },
    setFilterText(state, value) {
      state.filterText = value;
    },
    setFilterPriceRange(state, value) {
      state.filterPriceRange = value;
    },
    // actualiza uno o más campos de la propiedad actual
    updateActualProperty(state, propertyData) {
      // actualizar cada campo que se esta pasando
      let items = state.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i]._id === propertyData._id) {
          items[i] = propertyData;
        }
      }
      state = [];
      state.items = items;
    },
    setLoading(state, loading) {
      // establece que se esta cargando información
      state.loading = loading;
    }
  },
  actions: {
    fetchProperties({ commit }, realEstateCode) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          GetProperties(realEstateCode)
            .then(res => {
              let list = res.Property.sort((a, b) => {
                const na =
                  a.desarrollo && a.desarrollo.name ? a.desarrollo.name : "";
                const nb =
                  b.desarrollo && b.desarrollo.name ? b.desarrollo.name : "";

                const la = a.floor && a.floor.name ? a.floor.name : "";
                const lb = b.floor && b.floor.name ? b.floor.name : "";

                // const ca = a.code ? a.code : "";
                // const cb = b.code ? b.code : "";
                if (na > nb) {
                  return 1;
                }
                if (na < nb) {
                  return -1;
                }
                if (la > lb) {
                  return 1;
                }
                if (la < lb) {
                  return -1;
                }
                // if (ca > cb) {
                //   return 1;
                // }
                // if (ca < cb) {
                //   return -1;
                // }
                return 0;
              });

              commit("setProperties", list);
              resolve(res);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    // obtiene el property del API C28 y lo asigna como item actual
    fetchActualProperty({ commit }, propertyId) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          GetPropertyDetail(propertyId)
            .then(res => {
              commit("setActualProperty", res.Property);
              resolve();
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    // actualiza una propiedad con los parametros que se mandan
    // updateData: {
    //   propertyId: "####",
    //   property: {
    //      code: "ABC",
    //      contract_status: "apartado"
    //      price: "30000",
    //      currency: "USD"
    //    }
    // }
    fetchUpdatelProperty({ commit }, updateData) {
      try {
        let promises = [];

        if (!updateData.propertyId)
          throw new Error("No se especificó parámetro propertyId");
        if (!updateData.property)
          throw new Error("No se especificó parámetro property");

        let propertyId = updateData.propertyId;
        let dataProperty = {};

        // evaluar que datos se van a editar
        if (updateData.property) {
          // datos de la propiedad
          if (
            updateData.property.code ||
            updateData.property.price ||
            updateData.property.currency ||
            updateData.property.building_type ||
            updateData.property.contract_status ||
            updateData.property.building_type ||
            updateData.property.building_construction ||
            updateData.property.building_terrace ||
            updateData.property.building_total ||
            updateData.property.features_rooms ||
            updateData.property.features_bathrooms ||
            updateData.property.floor_name ||
            updateData.property.miscellaneous_mappingcoords
          ) {
            if (updateData.property.code)
              dataProperty.code = updateData.property.code;
            if (updateData.property.price || updateData.property.currency) {
              dataProperty.pricing = {};
              if (updateData.property.price) {
                dataProperty.pricing.price = "";
                dataProperty.pricing.price = updateData.property.price;
              }

              if (updateData.property.currency)
                dataProperty.pricing.currency = updateData.property.currency;
            }
            if (updateData.property.contract_status)
              dataProperty.contract_status =
                updateData.property.contract_status;

            if (updateData.property.building_type) {
              dataProperty.building = {};
              dataProperty.building.type = updateData.property.building_type;
            }
            if (
              updateData.property.building_type ||
              updateData.property.building_construction ||
              updateData.property.building_terrace ||
              updateData.property.building_total
            ) {
              dataProperty.building = {};
              dataProperty.building.type = updateData.property.building_type;
              dataProperty.building.construction =
                updateData.property.building_construction;
              dataProperty.building.terrace =
                updateData.property.building_terrace;
              dataProperty.building.total = updateData.property.building_total;
            }
            if (
              updateData.property.features_rooms ||
              updateData.property.features_bathrooms
            ) {
              dataProperty.features = {};
              dataProperty.features.rooms = updateData.property.features_rooms;
              dataProperty.features.bathrooms =
                updateData.property.features_bathrooms;
            }
            if (updateData.property.floor_name) {
              dataProperty.floor = {};
              dataProperty.floor.name = updateData.property.floor_name;
            }
            if (updateData.property.miscellaneous_mappingcoords) {
              dataProperty.miscellaneous = {};
              if (
                Array.isArray(updateData.property.miscellaneous_mappingcoords)
              ) {
                dataProperty.miscellaneous.mapping_coords =
                  updateData.property.miscellaneous_mappingcoords;
              } else {
                dataProperty.miscellaneous.mapping_coords = updateData.property.miscellaneous_mappingcoords
                  .split(",")
                  .map(i => Number(i, 10));
              }
            }
            promises.push(UpdateProperty(propertyId, dataProperty));
          }
        }
        if (promises.length === 0) return Promise.reject("Nada por editar");
        return Promise.all(promises)
          .then(values => {
            // actualizar tabla de prpiedades
            commit("updateActualProperty", values[0].Property);
            commit("setLoading", true);
            return Promise.resolve(values);
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchCreateAllProperty({ commit }, uProperties) {
      try {
        let promises = [];
        if (uProperties.length === 0)
          throw new Error("No se especificó los parametros");
        // if (!realEstateDevelopment)
        //   throw new Error("Datos del desarrollo no esta especificado");

        let dataProperty = {};

        uProperties.map(p => {
          dataProperty = {
            code: p.code,
            type: p.type,
            // información del precio
            pricing: {
              price: p["pricing/price"],
              currency: p["pricing/currency"]
            },
            // desarrollo que pertenece
            real_estate_development: {
              _id: p.real_estate_development._id,
              code: p.real_estate_development.code,
              name: p.real_estate_development.name
            },
            floor: {
              code: p["floor/code"],
              name: p["floor/name"]
            },
            tower: {
              code: p["tower/code"],
              name: p["tower/name"]
            },
            // información estructura de la unidad
            building: {
              // tipo de construcción
              type: p["building/type"],
              construction: p["building/construction"],
              construction_front: p["building/contruction_front"],
              construction_depth: p["building/construction_depth"],
              construction_depth_right: p["building/construction_depth_right"],
              construction_depth_left: p["building/construction_depth_left"],
              construction_back: p["building/construction_back"],
              terrace: p["building/terrace"],
              // metros cuadrados totales
              total: p["building/total"]
            },
            //construction_allowed
            construction_allowed: {
              unit: p["construction_allowed/unit"],
              total: p["construction_allowed/total"]
            },
            // amenidades de la unidad
            features: {
              rooms: p["features/rooms"],
              bathrooms: p["features/bathrooms"]
            },
            // información media
            media: {
              featured_image: {
                name: p["media/featured_image/name"],
                title: p["media/featured_image/title"],
                src: p["media/featured_image/src"],
                cloudinary_id: p["media/featured_image/cloudinary_id"]
              },
              plane_image: {
                name: p["media/plane_image/name"],
                src: p["media/plane_image/src"],
                cloudinary_id: p["media/plane_image/cloudinary_id"]
              }
            }
          };
          if (p.contract_status) {
            dataProperty.contract_status = p.contract_status;
          } else {
            dataProperty.contract_status = "indefinido";
          }
          dataProperty.miscellaneous = {};
          if (p["miscellaneous/mapping_coords"]) {
            const arrayMapCoodrs = p["miscellaneous/mapping_coords"];
            dataProperty.miscellaneous.mapping_coords = arrayMapCoodrs
              .split(",")
              .map(i => Number(i, 10));
          }
          if (p["miscellaneous/mapping_coords_building"]) {
            const arrayMapCoodrsBuilding =
              p["miscellaneous/mapping_coords_building"];
            dataProperty.miscellaneous.mapping_coords_building = arrayMapCoodrsBuilding
              .split(",")
              .map(i => Number(i, 10));
          }
          if (p["amenities"]) {
            const amenities = [];
            const amenitieArray = p.amenities.split(",");
            amenitieArray.forEach(amenitie => {
              const getcode = amenitie.split("-");
              let code;
              if (getcode.length > 0) {
                code = getcode[1];
              }
              amenities.push({
                code,
                abbreviation: amenitie
              });
            });
            dataProperty.amenities = amenities;
          }
          promises.push(CreateProperty(dataProperty));
        });

        if (promises.length === 0) return Promise.reject("Nada por editar");

        return Promise.all(promises)
          .then(values => {
            // actualizar tabla de prpiedades
            commit("setLoading", true);
            return Promise.resolve(values);
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
};
