<template>
  <div id="app">
    <v-app>
      <component :is="layout">
        <router-view :layout.sync="layout" />
      </component>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      token: localStorage.getItem("token"),
      layout: "div"
    };
  }
};
</script>
