<template>
  <v-container>
    <v-data-iterator
      :items="inmoLeads"
      hide-default-footer
      item-key="_id"
      :page="pagination.page"
      :items-per-page="pagination.limit"
      :loading="pagination.loading"
    >
      <template v-slot:header>
        <v-text-field
          v-model="search"
          clearable
          flat
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Buscar por nombre, email o teléfono"
          class="mb-4"
          @input="handelInput"
        ></v-text-field>
      </template>
      <template v-slot:loading>
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col cols="6">
            <v-progress-linear
              indeterminate
              color="primary"
              class="mt-5"
            ></v-progress-linear>
            <p>Cargando desarrollos...</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:default="props">
        <v-card v-for="item in props.items" :key="item._id" class="mb-1">
          <v-list-item>
            <v-checkbox></v-checkbox>

            <v-list-item-avatar>
              <v-avatar size="25">
                <v-img :src="item.source" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="px-2">
              <v-list-item-subtitle>{{
                formatDate(item.created_at)
              }}</v-list-item-subtitle>

              <v-list-item-title class="text-h6 font-weight-regular">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="px-4">
              <v-list-item-subtitle>
                {{
                  item.registration === "automatic" ? "Automático" : "Manual"
                }}
                •
                <!-- dupliucado -->
                <span :class="calculeColor(item.duplicated)">
                  {{ calculeDuplicate(item.duplicated) }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="px-4">
              <v-list-item-subtitle>
                <v-icon small>
                  mdi-email
                </v-icon>
                {{ item.email }}
              </v-list-item-subtitle>

              <v-list-item-subtitle>
                <v-icon small>
                  mdi-phone
                </v-icon>
                {{ item.phone }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-row class="justify-center">
                <!-- icon buttons -->

                <v-btn icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="clickDelete(item._id)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-row
          class="pagination-footer ma-0 pa-0 py-2 px-5"
          align="center"
          justify="end"
        >
          <span class="mr-4 caption">
            page: {{ pagination.page }} de
            {{ Math.ceil(pagination.total / pagination.limit) }}
          </span>

          <span class="caption">Leads por página</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-0 caption"
                v-bind="attrs"
                v-on="on"
              >
                {{ pagination.limit }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="setLimit(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <span class="mr-4 caption"> {{ rangePage }}</span>
          <v-btn
            icon
            color="primary"
            class="mr-1"
            small
            :disabled="pagination.page === 1"
            @click="prevPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            icon
            color="primary"
            class="ml-1"
            small
            :disabled="
              pagination.page * pagination.limit >= pagination.total ||
                pagination.loading
            "
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "LeadsByInmo",
  components: {},
  data() {
    return {
      inmoId: this.$route.params.id,
      itemsPerPageArray: [5, 10, 20, 25],
      search: "",
      timer: null,
      delay: 500,
      confirm: false
    };
  },
  async mounted() {
    await this.fetchInmoLeads({ inmoId: this.inmoId });
  },
  // limpiar la paginación al salir del componente
  beforeDestroy() {
    this.resetPagination();
  },
  computed: {
    ...mapGetters("root", {
      inmoLeads: "getInmoLeads",
      pagination: "getPaginationLeads"
    }),
    rangePage() {
      const end = this.pagination.page * this.pagination.limit;
      const start = end - this.pagination.limit;
      return `${start}-${end} de ${this.pagination.total}`;
    }
  },
  methods: {
    ...mapActions("root", {
      fetchInmoLeads: "fetchListLeadsByInmo",
      resetPagination: "resetPagination",
      deleteLead: "deleteLead"
    }),
    ...mapMutations("root", {
      setLimit: "setLimit",
      setPage: "setPage"
    }),
    async nextPage() {
      this.setPage(this.pagination.page + 1);
      // esta validación es para evitar que se haga la petición si ya se cargaron todos los leads
      // o retrosedio de página
      if (
        this.pagination.page * this.pagination.limit < this.inmoLeads.length ||
        this.pagination.total === this.inmoLeads.length
      )
        return;
      await this.fetchInmoLeads({ inmoId: this.inmoId, search: this.search });
    },
    prevPage() {
      this.setPage(this.pagination.page - 1);
    },
    changeSearch() {
      this.resetPagination();
      this.fetchInmoLeads({
        inmoId: this.inmoId,
        search: this.search
      });
    },
    handelInput() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.changeSearch();
      }, this.delay);
    },
    async handleDelete(id) {
      const res = await this.deleteLead({ leadId: id });
      if (!res.data)
        return this.$snotify.error(
          "Hubo un error al eliminar el Lead",
          "Error :|"
        );
      this.$snotify.success("Lead eliminado correctamente", "Success");
    },
    clickDelete(id) {
      this.confirm = true;

      this.$confirmation(
        "Eliminar Lead",
        "¿Estas seguro que deseas eliminar este Lead?",
        () => this.handleDelete(id)
      );
    },
    calculeDuplicate(duplicated) {
      // true: duplicado, false: unico, null: no info
      if (duplicated === null) return "No info";
      return duplicated ? "Duplicado" : "Unico";
    },
    calculeColor(duplicated) {
      // true: duplicado, false: unico, null: no info
      if (duplicated === null) return "orange--text";
      return duplicated ? "red--text" : "green--text";
    },
    formatDate(date) {
      const newDate = new Date(date);
      const formattedDate = newDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
      const formattedTime = newDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true
      });

      return `${formattedDate}, ${formattedTime}`;
    }
  }
};
</script>
