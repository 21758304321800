<template>
  <v-tooltip :[align]="true">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="primary"
        @click="executeAction"
        :[size]="true"
        dark
        v-bind="attrs"
        v-on="on"
      >
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "CustomToolTip",
  props: {
    icon: {
      type: String,
      default: "mdi-home"
    },
    text: {
      type: String,
      default: "Tooltip"
    },
    align: {
      type: String,
      default: "bottom"
    },
    size: {
      type: String,
      default: ""
    },
    action: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      showSlot: true
    };
  },
  methods: {
    executeAction() {
      if (this.action) {
        this.action();
      }
    }
  }
};
</script>
