<template>
  <v-container>
    <!-- Loading -->
    <v-overlay :value="loadingInmoInspect">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <dir v-if="!loadingInmoInspect">
      <div class="d-flex ma-2 pa-2">
        <h1>{{ inmo.name }}</h1>
        <v-chip small class="white--text" :color="status.color">{{
          status.text
        }}</v-chip>
        <v-spacer></v-spacer>
        <!-- Generate token -->
        <v-btn color="primary" small @click.native.stop="dialog = true">
          <v-icon dense>
            mdi-key-variant
          </v-icon>
          Token
        </v-btn>
        <v-dialog v-model="dialog" max-width="550">
          <GenerateToken :group-id="inmo._id" />
          <!-- close -->
          <v-btn class="" color="primary" @click.native.stop="dialog = false">
            Cerrar
          </v-btn>
        </v-dialog>
      </div>
      <v-form @submit.prevent="">
        <v-expansion-panels class="mx--5" multiple v-model="expand" popout>
          <!-- General -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>General</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <GeneralInfoForm :dataInmo="mockInmo" />
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
          <!-- media -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Media</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MediaForm :mediaInmo="inmo.media" />
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
          <!-- Source and Mediums -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Trafico</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TrafficsByInmo :inmoId="inmo._id" />
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>

          <!-- address -->
          <!-- Implementar update -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Direccion</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Latitud"
                    required
                    v-model="mockInmo.address.google_maps_address.lat"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Longitud"
                    required
                    v-model="mockInmo.address.google_maps_address.lng"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Link"
                    required
                    v-model="mockInmo.address.google_maps_address.link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Iframe"
                    required
                    v-model="mockInmo.address.google_maps_address.iframe"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Pais"
                    v-model="mockInmo.address.country"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Estado"
                    required
                    v-model="mockInmo.address.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Ciudad"
                    v-model="mockInmo.address.city"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Direccion"
                    v-model="mockInmo.address.full_address"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
          <!-- contact -->
          <!-- Implementar update -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Contacto</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Email"
                    required
                    v-model="mockInmo.contact_data.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Telefono"
                    required
                    v-model="mockInmo.contact_data.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Web"
                    required
                    v-model="mockInmo.contact_data.web"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Facebook"
                    required
                    v-model="mockInmo.contact_data.social_networks.facebook"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Instagram"
                    required
                    v-model="mockInmo.contact_data.social_networks.instagram"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Twitter"
                    required
                    v-model="mockInmo.contact_data.social_networks.twitter"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </dir>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GeneralInfoForm from "./forms/RealEstateGroup/generalInfoForm.vue";
import MediaForm from "./forms/RealEstateGroup/mediaForm.vue";
import GenerateToken from "./forms/generateToken/GenerateToken.vue";
import TrafficsByInmo from "./components/TrafficsByInmo.vue";

const getDefaultInmo = () => {
  return {
    name: "",
    address: {
      // google maps
      google_maps_address: {
        lat: "",
        lng: "",
        // link google maps
        link: "",
        // link iframe para incrustar en sitios web
        iframe: ""
      },
      country: "",
      state: "",
      city: "",
      full_address: ""
    },
    contact_data: {
      email: "",
      phone: "",
      web: "",
      // redes sociales
      // pensar en estandarizar con otros modelos seccion contact.social_networks
      social_networks: {
        facebook: "",
        instagram: "",
        twitter: ""
      }
    },
    media: {
      logo: {
        name: "",
        alt_text: "",
        src: "",
        cloudinary_id: ""
      }
    },
    real_estate_phase: [],
    active: true,
    assignment_method: 1,
    status: 1,
    activate_duplicate_contact_lead_search: "on",
    general_active: "off",
    moduleQuote: "on",
    search_duplicates_just_one_value_carrousel: "off",
    search_for_duplicate_names: "off",
    view_all_development: "off"
  };
};
export default {
  name: "DetailByInmo",
  components: {
    GeneralInfoForm,
    MediaForm,
    GenerateToken,
    TrafficsByInmo
  },
  data() {
    return {
      expand: [0],
      mockInmo: Object.assign({}, getDefaultInmo()),
      dialog: false
    };
  },
  computed: {
    ...mapGetters("root", {
      inmo: "getInmoInspect"
    }),
    ...mapState("root", {
      loadingInmoInspect: state => state.loadingInmoInspect
    }),
    status() {
      const active = { color: "green", text: "Activo" };
      const inactive = { color: "red", text: "Desactivado" };
      if (this.inmo.active === undefined || this.inmo.active === null)
        return active;
      return this.inmo.active ? active : inactive;
    }
  },
  watch: {
    loadingInmoInspect(val) {
      if (!val) {
        this.copyInmo(this.inmo);
      }
    }
  },
  methods: {
    copyInmo(inmo) {
      const { address, contact_data, media, ...others } = inmo;
      this.mockInmo = { ...getDefaultInmo(), ...others };
      this.mockInmo.address.google_maps_address = {
        ...getDefaultInmo().address.google_maps_address,
        ...address.google_maps_address
      };
      this.mockInmo.address = { ...getDefaultInmo().address, ...address };
      this.mockInmo.contact_data.social_networks = {
        ...getDefaultInmo().contact_data.social_networks,
        ...contact_data.social_networks
      };
      this.mockInmo.contact_data = {
        ...getDefaultInmo().contact_data,
        ...contact_data
      };
      this.mockInmo.media.logo = {
        ...getDefaultInmo().media.logo,
        ...media.logo
      };
    }
  }
};
</script>

<style></style>
