import {
  ReportCreateColumn,
  ReportGetRowColumn,
  ReportUpdateColumn,
  getDataByComertialReport,
  fetchReportDetail,
  fetchListLeadsInAnualMKT,
  getDataByCampaignReport,
  fetchCampaigns
} from "@/api/reports-api.js";
export default {
  namespaced: true,
  state: {
    isLoadingReportList: false,
    isLoadingReportUpdate: false,
    isLoadingReportCreate: false,
    reportsList: [],
    comertialReport: { result: [], resumen: {} },
    leadListByComertialReport: [],
    leadListByAnualMKTMonth: [],
    leadListByAnualMKTYear: [],
    campaignReport: [],
    campaigns: []
  },
  getters: {
    campaignList: state => state.campaigns
  },
  mutations: {
    // Inicio Anual MKT
    setLeadListByAnualMKTMonth(state, data) {
      state.leadListByAnualMKTMonth = data;
    },
    setLeadListByAnualMKTYear(state, data) {
      state.leadListByAnualMKTYear = data;
    },
    // Fin Anual MKT
    setComertialReport(state, data) {
      state.comertialReport = data;
    },
    setCampaignReport(state, data) {
      state.campaignReport = data;
    },
    setLeadListByComertialReport(state, data) {
      if (Array.isArray(data)) {
        state.leadListByComertialReport = data;
      } else {
        state.leadListByComertialReport = [];
      }
    },
    setLoadingReportList(state, val) {
      state.isLoadingReportList = val;
    },
    setLoadingReportUpdate(state, val) {
      state.isLoadingReportUpdate = val;
    },
    setLoadingReportCreate(state, val) {
      state.isLoadingReportCreate = val;
    },
    setReportsList(state, list) {
      state.reportsList = list;
    },
    setCampaigns(state, list) {
      state.campaigns = list;
    }
  },
  actions: {
    async fetchDataByCampaignReport({ commit }, filter) {
      try {
        const json = await getDataByCampaignReport(filter);
        commit("setCampaignReport", json.data);
      } catch (error) {
        commit("setCampaignReport", []);
      }
    },
    // Inicio Anual MKT
    async fetchListLeadsInAnualMKT({ commit }, filter) {
      try {
        const json = await fetchListLeadsInAnualMKT(filter);
        //Se envia el commit del nuevo valor
        if (filter.groupBy === "month") {
          commit("setLeadListByAnualMKTMonth", json.data);
        } else {
          commit("setLeadListByAnualMKTYear", json.data);
        }
      } catch (error) {
        commit("setLeadListByAnualMKTYear", {});
      }
    },
    // Fin Anual MKT
    async fetchDataByComertialReport({ commit }, filter) {
      try {
        const json = await getDataByComertialReport(filter);
        commit("setComertialReport", json.data);
      } catch (error) {
        commit("setComertialReport", { result: [], resumen: {} });
      }
    },
    async fetchListLeadsByDetailReport({ commit }, filter) {
      try {
        const typeReport = filter.typeReport;
        const json = await fetchReportDetail(typeReport, filter);
        commit("setLeadListByComertialReport", json.data);
      } catch (error) {
        commit("setLeadListByComertialReport", []);
      }
    },
    fecthReportList({ commit }) {
      commit("setLoadingReportList", true);
      try {
        return new Promise(resolve => {
          ReportGetRowColumn()
            .then(res => {
              commit("setReportsList", res.data.Reports);
              commit("setLoadingReportList", false);
              resolve(res);
            })
            .catch(error => {
              commit("setReportsList", []);
              commit("setLoadingReportList", false);
              throw new Error(error);
            });
        });
      } catch (error) {
        commit("setReportsList", []);
        commit("setLoadingReportList", false);
        return Promise.reject(error);
      }
    },
    createReportCreateColumn({ commit }, body) {
      commit("setLoadingReportCreate", true);
      try {
        return new Promise(resolve => {
          ReportCreateColumn(body)
            .then(res => {
              commit("setLoadingReportCreate", false);
              resolve(res);
            })
            .catch(error => {
              commit("setLoadingReportCreate", false);
              throw new Error(error);
            });
        });
      } catch (error) {
        commit("setLoadingReportCreate", false);
        return Promise.reject(error);
      }
    },
    updateReportUpdateColumn({ commit }, body) {
      commit("setLoadingReportUpdate", true);
      try {
        return new Promise(resolve => {
          ReportUpdateColumn(body)
            .then(res => {
              commit("setLoadingReportUpdate", false);
              resolve(res);
            })
            .catch(error => {
              commit("setLoadingReportUpdate", false);
              throw new Error(error);
            });
        });
      } catch (error) {
        commit("setLoadingReportUpdate", false);
        return Promise.reject(error);
      }
    },
    async getCampaigns({ commit }) {
      try {
        const json = await fetchCampaigns();
        if (!json.data) throw new Error("No data");
        commit("setCampaigns", json.data.campaigns);
      } catch (error) {
        commit("setCampaign", []);
      }
    }
  }
};
