<template>
  <v-autocomplete
    v-model="selected"
    :items="sourcesNotSelected"
    chips
    color="blue-grey lighten-2"
    item-text="name"
    item-value="_id"
    :hint="`Click en el icono para ${!isEditing ? 'Editar' : 'Guardar'}`"
    :readonly="!isEditing"
    label="Añadir fuente"
    persistent-hint
    class="autocomplete__traffics"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.media.src"></v-img>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <img :src="data.item.media.src" size="20" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.abbreviation }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </template>
    </template>
    <template v-slot:append-outer>
      <v-slide-x-reverse-transition mode="out-in">
        <v-icon
          :key="`icon-${isEditing}`"
          :color="isEditing ? 'success' : 'info'"
          @click="isEditing ? save() : edit()"
          v-text="isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
        ></v-icon>
      </v-slide-x-reverse-transition>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "SelectSources",
  components: {},
  data() {
    return {
      selected: [],
      isEditing: false,
      inmoId: this.$route.params.id
    };
  },
  computed: {
    ...mapGetters("root", {
      sourcesByInmo: "sourcesByInmo"
    }),
    ...mapState("root", {
      sources: state => state.sources
    }),
    sourcesNotSelected() {
      return this.sources.filter(
        source =>
          this.sourcesByInmo.findIndex(
            item => item.source._id === source._id
          ) === -1
      );
    }
  },
  methods: {
    ...mapActions("root", {
      createTraffic: "createTraffic"
    }),
    remove(item) {
      const index = this.selected.indexOf(item._id);
      if (index >= 0) this.selected.splice(index, 1);
    },
    edit() {
      this.isEditing = !this.isEditing;
    },
    async save() {
      if (!this.selected.length) return (this.isEditing = false);

      const res = await this.createTraffic({
        inmoId: this.inmoId,
        id: this.selected,
        type: "source"
      });
      if (res.data) {
        this.$snotify.success("Fuente añadida correctamente", "Completado");
      } else {
        this.$snotify.error(`${res.Message}`, "Error");
      }
      this.selected = [];
      this.isEditing = false;
    }
  }
};
</script>

<style>
.autocomplete__traffics {
  max-width: 425px;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
