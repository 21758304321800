<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Nombre"
          hint="Nombre de la Inmobiliaria."
          persistent-hint
          :readonly="!edit"
          v-model="mockInmo.name"
          @input="changeDataEdit('name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          label="Fases ocultas"
          hint="Las fases ocultas no apareceran en los Leads ni en los reportes."
          persistent-hint
          :readonly="!edit"
          multiple
          v-model="mockInmo.real_estate_phase"
          @input="changeDataEdit('real_estate_phase', $event)"
          :items="phases"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          disabled
          label="Estado"
          hint="Deprecado, verificar que impacto tiene en el sistema"
          persistent-hint
          :readonly="!edit"
          v-model="mockInmo.status"
          @input="changeDataEdit('status', $event)"
          :items="[
            { text: 'Activo', value: 1 },
            { text: 'Inactivo', value: 2 }
          ]"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-select
          label="Activo"
          hint="Cuando una Inmo se desactiva, siguien llegando Leads,
                      pero los usuario no pueden acceder a su
                      cuenta."
          persistent-hint
          :readonly="!edit"
          v-model="mockInmo.active"
          @input="changeDataEdit('active', $event)"
          :items="[
            { text: 'Si', value: true },
            { text: 'No', value: false }
          ]"
        ></v-select>
      </v-col>
    </v-row>
    <v-list>
      <v-subheader>Asignacion</v-subheader>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Metodo de Asignacion</v-list-item-title>
          <v-list-item-subtitle class="text-wrap">
            Carrusel: Los Leads se asignaran de manera automatica a los
            asesores.
            <br />
            Manual: Los Leads entraran con la fase de Por Asignar.
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-select
            :readonly="!edit"
            v-model="mockInmo.assignment_method"
            @input="changeDataEdit('assignment_method', $event)"
            :items="[
              { text: 'Carrusel', value: 1 },
              { text: 'Manual', value: 0 }
            ]"
          ></v-select>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            :readonly="!edit"
            v-model="mockInmo.general_active"
            @change="event => changeDataEdit('general_active', event)"
            true-value="on"
            false-value="off"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Carrusel General</v-list-item-title>
          <v-list-item-subtitle class="text-wrap">
            Si esta activo, todos los Leads que no entren por un Carrusel
            especifico, entraran por defecto a este Carrusel.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-subheader>Cotizador</v-subheader>
      <v-list-item>
        <template>
          <v-list-item-action>
            <v-checkbox
              :readonly="!edit"
              v-model="mockInmo.moduleQuote"
              @change="event => changeDataEdit('moduleQuote', event)"
              true-value="on"
              false-value="off"
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Habilitar cotizador</v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              Activar el modulo de cotizador para esta inmobiliaria
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-subheader>Duplicados</v-subheader>
      <v-list-item>
        <template>
          <v-list-item-action>
            <v-checkbox
              :readonly="!edit"
              v-model="mockInmo.activate_duplicate_contact_lead_search"
              @change="
                event =>
                  changeDataEdit(
                    'activate_duplicate_contact_lead_search',
                    event
                  )
              "
              true-value="on"
              false-value="off"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Buscar Leads duplicados</v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              Si esta activo, el sistema buscara duplicados en los Leads que
              entren a esta inmobiliaria.
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-list-item>
        <template>
          <v-list-item-action>
            <v-checkbox
              :readonly="!edit"
              v-model="mockInmo.search_for_duplicate_names"
              @change="
                event => changeDataEdit('search_for_duplicate_names', event)
              "
              true-value="on"
              false-value="off"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Buscar duplicados por nombre</v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              Si esta activo, el sistema buscara duplicados por nombre. Por
              defecto, el sistema solo busca duplicados por correo y telefono.
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-list-item>
        <template>
          <v-list-item-action>
            <v-checkbox
              :readonly="!edit"
              v-model="mockInmo.search_duplicates_just_one_value_carrousel"
              @change="
                event =>
                  changeDataEdit(
                    'search_duplicates_just_one_value_carrousel',
                    event
                  )
              "
              true-value="on"
              false-value="off"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >Tomar en cuenta solo un campo, como duplicado</v-list-item-title
            >
            <v-list-item-subtitle class="text-wrap">
              Si esta activo, el sistema considerara a un Lead como duplicado si
              alguno de sus campos coincide con el de otro Lead. Por defecto, el
              sistema considera duplicado si coinciden el correo, telefono y el
              nombre (si esta activo).
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-subheader>Otros</v-subheader>
      <v-list-item>
        <template>
          <!--   -->
          <v-list-item-action>
            <v-checkbox
              :readonly="!edit"
              v-model="mockInmo.view_all_development"
              @change="event => changeDataEdit('view_all_development', event)"
              true-value="on"
              false-value="off"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Ver todos los desarrollos</v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              Si esta activo, los roles de admin, broker y coordinator podran
              ver todos los desarrollos de esta inmobiliaria.
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
    <section class="d-flex justify-end">
      <v-btn v-if="!edit" @click="edit = true" color="primary" class="mx-2"
        >Editar</v-btn
      >
      <v-btn v-if="edit" @click="update" color="success" class="mx-2"
        >Actualizar</v-btn
      >
      <v-btn v-if="edit" @click="cancelEdit" color="error" class="mx-2"
        >Cancelar</v-btn
      >
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "GeneralInfoForm",
  props: {
    dataInmo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      dataEdit: {},
      phases: [
        { text: "Por Asignar", value: "unassigned" },
        { text: "Asignado", value: "assigned" },
        {
          text: "Por Perfilar",
          value: "to-contact"
        },
        { text: "Busqueda", value: "searching" },
        { text: "Seguimiento", value: " tracking" },
        { text: "Recorrido", value: "scheduled-tour" },
        { text: "Seguimiento post-recorrido", value: "finished-tour" },
        { text: "Oferta", value: "offer" },
        {
          text: "Apartado",
          value: "downpayment"
        },
        {
          text: "Promesa",
          value: "contract"
        },
        {
          text: "Cierre",
          value: "closing-trade"
        }
      ],
      mockInmo: this.dataInmo
    };
  },
  methods: {
    ...mapActions("root", {
      updateInmo: "updateInmo"
    }),
    changeDataEdit(key, value) {
      this.dataEdit[key] = value;
    },
    cancelEdit() {
      this.edit = false;
      this.dataEdit = {};
      this.copyData();
    },
    copyData() {
      this.mockInmo = { ...this.dataInmo };
    },
    async update() {
      const id = this.$route.params.id;
      const res = await this.updateInmo({ id, inmo: this.dataEdit });
      if (res) {
        this.edit = false;
        this.dataEdit = {};
      }
      if (res.data)
        return this.$snotify.success("Inmobiliaria actualizada", "Completado");
      this.$snotify.error("No se pudo actualizar inmobiliaria", "Error");
    }
  }
};
</script>

<style></style>
