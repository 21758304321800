<template>
  <v-container fluid>
    <v-text-field
      v-model="search"
      clearable
      flat
      hide-details
      prepend-inner-icon="mdi-magnify"
      label="Search"
      class="mt-2"
    ></v-text-field>
    <GridDevelopments
      :developments="developments"
      :search="search"
      :loading="loading"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GridDevelopments from "./GridDevelopments.vue";
export default {
  name: "DevelopmentsByInmo",
  components: {
    GridDevelopments
  },
  async created() {
    this.loading = true;
    await this.fetchDevelopmentsByInmo(this.inmo._id);
    this.loading = false;
  },
  mounted() {},
  data() {
    return {
      search: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters("root", {
      developments: "getDevelopmentsByInmoInspect",
      inmo: "getInmoInspect"
    })
  },
  methods: {
    ...mapActions("root", {
      fetchDevelopmentsByInmo: "fetchDevelopmentsByInmo"
    })
  }
};
</script>

<style></style>
