import request from "../helpers/request";

// obtiene la lista de logs
// solo para usuarios root
export function GetLogs() {
  try {
    let data = {};

    return new Promise((resolve, reject) => {
      request("/log/getLog", data)
        .then(response => {
          resolve({
            Log: response.data.Log
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
